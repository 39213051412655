/********************************
관련자료 START
********************************/
main.refs {
    padding-top: 60px;
    padding-bottom: 140px;

    .search {
        margin-bottom: 30px;
    }

    .list {
        margin-bottom: 30px;
        border-top: 2px solid #333;
        border-bottom: 1px solid #333;
    }

    .list-item {
        padding: 20px;

        &:first-child {
            border-top: none;
        }

        + .list-item {
            border-top: 1px solid #ddd;
        }
    }

    img {
        width: 160px;
        height: 120px;
    }

    .content {
        padding: 0 30px;
    }

    h2 {
       font-size: 24px;
       color: $text-dark; 
    }

    p {
        margin-top: 20px;
        font-size: 18px;
    }

    .btn-download {
        width: 150px;
        height: 48px;
        line-height: 48px;
    }
    
    .btn-download + .btn-download {
        margin-left: 6px;
    }
}
/********************************
관련자료 END
********************************/