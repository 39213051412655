/********************************
체험관 보기 START
********************************/
main.arremoteview {
    padding-top: 60px;
    padding-bottom: 150px;

    dl {
        margin-bottom: 40px;
        border-top: 2px solid #333;
        border-bottom: 1px solid #333;
    }

    dt {
        padding: 45px 30px;
        border-bottom: 1px solid #dddddd;
    }

    h2 {
        font-size: 24px;
        color: $text-dark;
    }

    .yymmdd {
        font-size: 16px;
    }

    .yymmdd-title {
        display: inline-block;
        margin-right: 20px; 
        color: $text-dark;
    }

    .yymmdd-text {
        color: $text-light;
    }

    dd {
        padding: 60px 30px;
        font-size: 16px;
        line-height: 32px;
    }
}
/********************************
체험관 보기 END
********************************/