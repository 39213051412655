/********************************
component: button START
********************************/
.btn-download {
    width: 200px;
    height: 60px;
    padding: 0 20px;
    border: 2px solid #29244b;
    font-weight: 500;
    line-height: 60px;
    text-align: center;
    color: #29244b;

    @include flexbox();
    @include align-items(center);

    i {
        display: inline-block;
        margin-left: auto;

        @include bg(18, 18, icon-download);
    }
}

.btn {
    width: 160px;
    height: 60px;
    line-height: 60px;

    &.type-list {
        color: white;
        background-color: #333;
    }

    &.type-primary {
        color: white;
        background-color: $brand-color;
    }

    &.type-second {
        color: white;
        background-color: $text-light;
    }

    &.type-more {
        width: 200px;
        padding-left: 30px;
        padding-right: 25px;
        border: 2px solid $brand-color;
        color:  $brand-color;

                
        @include flexbox();
        @include align-items(center);

        i {
            margin-left: auto;
            
            @include bg(24, 6, icon-arrow-more);
        }

        &.type-white {
            border-color:white;
            color: white;

            i {
                @include bg(24, 6, icon-arrow-more-white);
            }
        }
    }
}
/********************************
component: button END
********************************/