/********************************
납품실적 보기 START
********************************/
main.delperview {
    padding-top: 100px;
    padding-bottom: 150px;

    .slider {
        @include flexbox();
        @include align-items(center);
    }
    figure {
        position: relative;
    }

    figcaption {
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 120px;
        padding: 0 40px;
        background-color: rgba(0, 0, 0, .6);

        @include flexbox();
        @include align-items(center);
    }

    .list-caption {
        @include flexbox();
        @include align-items(center);
    }

    .caption-title {
        margin-right: 80px;
        font-size: 28px;
        font-weight: 500;
        color: white;
    }

    .caption-text {
        font-size: 18px;
        font-weight: 100;
        color: white;

        + .caption-text {
            margin-left: 20px;
        }
    }

    .list-item {
        padding: 0 15px;
    }

    .list-img {
        width: 250px;
        height: 180px;
    }

    .btn-prev {
        margin-right: 20px;

        @include bg(18, 30, btn-prev-big);
    }

    .btn-next {
        margin-left: 20px;
        transform: rotate(-180deg);

        @include bg(18, 30, btn-prev-big);
    }

    .divider {
        width: 100%;
        height: 1px;
        margin-top: 50px;
        margin-bottom: 50px;
        background-color: #ddd;

        &.type-dark {
            background-color: #333;
        }
    }

    .selected-img {
        width: 1200px;
        height: 600px;
    }
}
/********************************
납품실적 보기 END
********************************/