/********************************
회사 연혁 START
********************************/
main.histroy {
    padding-top: 90px;
    padding-bottom: 150px;
    background-color: #f8f8f8;
    
    @include bg-pos(0,0,comp_history_img_1);

    .list {
        padding-left: 690px;
        color: #666;
    }

    .list-item {
        font-size: 18px;
        font-weight: 100;

        + .list-item {
            margin-top: 45px;
        }
    }

    .list-content {
        li {
            + li {
                margin-top: 20px;
            }
        }
    }

    strong {
        display: block;
        margin-bottom: 30px;
        font-size: 36px;
        font-family: 'Roboto';
        font-weight: 700;
        color: $text-dark;
        
    }

    span {
        display: inline-block;
        margin-right: 20px;
        color: $text-dark;
    }
}

/********************************
회사 연혁 END
********************************/