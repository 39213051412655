/********************************
component: form-control START
********************************/
.form-control {
    display: block;
    width: 100%;
    height:48px;
    padding: 0 20px;
    font-size: 16px;
    line-height: 48px;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dddddd;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
/********************************
component: form-control END
********************************/