/********************************
helper class START
********************************/
/* display */
.d-flex {
    @include flexbox();
}

.d-flex-align-center {
    @include flexbox;
    @include align-items(center);
}

.ml-auto {
    margin-left: auto;
}

/* font-family Noboto*/
.font-lg {
    font-weight: 100;
}

.font-reg {
    font-weight: 300;
}

.font-md {
    font-weight: 500;
}

.font-bold {
    font-weight: 700;
}

/* font-family Roboto*/
.ro-font-lg {
    font-family: 'Roboto';
    font-weight: 100;
}

.ro-font-reg {
    font-family: 'Roboto';
    font-weight: 300;
}

.ro-font-md {
    font-family: 'Roboto';
    font-weight: 500;
}

.ro-font-bold {
    font-family: 'Roboto';
    font-weight: 700;
}

/* colors */
.text-dark {
    color: $text-dark;
}

.text-brand {
    color: $brand-color;
}

.text-white {
    color: white !important;
}

/* align */
.text-center {
    text-align: center !important;
}

.text-right {
    text-align: right !important;
}

.text-left {
    text-align: left !important;
}

/*blocks*/
body {
    color: #666;
}

.w-inner {
    width: 1200px;
    margin: 0 auto;
} 

.search {
    @include flexbox();

    .dropdown {
        width: 120px;
    }

    input[type="text"] {
        width: 380px; 
        margin-left: 5px;
    }

    .btn-search {
        margin-left: 5px;

        @include bg (48, 48, btn-search);
    }
}

.icon-attached {
    display: inline-block;
    
    @include bg(18, 19, icon-attached);
}

.fadeout {
    opacity: 0;
    -moz-transition: opacity 0.8s ease-in-out;
    -o-transition: opacity 0.8s ease-in-out;
    -webkit-transition: opacity 0.8s ease-in-out;
    transition: opacity 0.8s ease-in-out;
}

.fadein {
    opacity: 1;
}
/********************************
helper class END
********************************/
