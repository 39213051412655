/********************************
component: pagination START
********************************/
/* 공통 ui START */
.pagenation {
    font-size: 16px;
    text-align: center;
    
    a {
        display: inline-block;
        width: 35px;
        color: $text-light;
        
        &.on,
        &:hover {
            color: $text-base;
        }
    }

    .btn {
        width: 20px; 
    }

    .btn-prev {
        margin-right: 20px;

        @include bg(8, 12,btn-prev);
    }

    .btn-prev-10 {
        @include bg(14, 12,btn-prev-10);
    }

    .btn-next {
        margin-left: 20px;
        transform: rotate(180deg);

        @include bg(8, 12,btn-prev);
    }

    .btn-next-10 {
        transform: rotate(180deg);

        @include bg(14, 12,btn-prev-10);
    }

    button + button {
        margin-left: 15px;
    }
}
/********************************
component: pagination END
********************************/