/********************************
faq START
********************************/
main.faq {
    padding-top: 80px;
    padding-bottom: 150px;

    dl {
        margin-top: 30px;
        margin-bottom: 30px;
        border-top: 2px solid #333;
        border-bottom: 1px solid #333;
    }

    dt { 
        width: 100%;
        padding: 30px 30px 30px 80px;
        border-bottom: 1px solid #ddd;

        @include bg-pos(30px, 50%, faq-txt-q);

        &:last-of-type {
            border-bottom: none;
        }

        &.open {
            button {
                @include bg(18, 18, faq-icon-minus); 
            }

            + dd {
                display: block;
                border-bottom: none;
            }
        }

        button {
            @include bg(18, 18, faq-icon-plus);
        }
    }

    strong {
        display: inline-block;
        width: 100px;
        color: $text-dark;
    }

    dd {
        display: none;
        padding: 30px 30px 30px 80px;
        line-height: 30px;
        background-color: #f4f4f4;

        @include bg-pos(30px, 40px, faq-txt-a);

        &:last-child {
            border-bottom: none; 
        }
    }
}
/********************************
faq END
********************************/