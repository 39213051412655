/********************************
납품실적 START
********************************/
main.delper {
    padding-top: 100px;
    padding-bottom: 150px;

    .caption {
        margin-top: 88px;
        margin-left: 40px;

        h2 {
            font-size: 28px;
            color: $text-dark;
        }

        p {
            margin-top: 30px;
            font-size: 18px; 
            line-height: 28px; 
        }
    }

    .list {
        flex-wrap: wrap;
        margin-top: 70px;
    }
    
    .list-item {
        margin-right: 30px;

        &:nth-child(3n) {
            margin-right: 0;
        }

        + .list-item {
            margin-bottom: 75px;
        }
    }

    figure {
        position: relative;

        img {
            width: 380px;
            height: 325px;
        }
    }

    figcaption {
        margin-top: 30px;

        p {
            font-size: 24px;
            color: $text-dark;
        }

        span {
            display: inline-block;
            margin-top: 25px;
            font-size: 16px;
            color: $text-light;
        }
    }

    .btn-play {
        z-index: 10;
        position: absolute;
        top: 45%;
        right: 0;
        left: 0;
        margin: 0 auto;

        @include bg(80, 80, comp-delper-img-play);
    }

    .slider-wrapper { 
        position: relative;

        .txt-performance {
            z-index: 5;
            position: absolute;
            top:-24px;
            left:-366px;
            font-size: 150px;
            letter-spacing: -6px;
            text-transform: uppercase;
            opacity: 0.6;
            color: #ededed;
        }
    }
}
/********************************
납품실적 END
********************************/