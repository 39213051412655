/********************************
aside START
********************************/
.aside {
    width: 485px;
    min-height: 70px;
    margin-top: auto;
    border-left: 1px solid #7529cb;
    background-color: $brand-color;

    i {
        display: inline-block;
        width: 69px;
        height: 100%;
        border-left: 1px solid #7529cb;
        
        @include bg-pos(50%, 50%, comp-submain-icon);
    }

    .aside-dropdown {
        position: relative;
        width: 210px;
        height: auto;
        padding: 0 20px;
        border-left: 1px solid #7529cb;
    }

    .aside-toggle {
        display: block;
        width: 100%;
        height: 70px;
        padding-right: 10px;
        font-size: 16px;
        line-height: 70px;
        text-align: left;

        @include bg-pos(100%, 50%, icon-arrow-south-white);

        &.on {
            @include bg-pos(100%, 50%, icon-arrow-north-white);
        }
    }

    .aside-menu {
        display: none;
        z-index: 10;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: auto;
        border: 1px solid #dddddd;
        text-align: left;
        background-color: #fff;
    }

    .aside-item {
        display: inline-block;
        width: 100%;
        font-size: 16px;
        white-space: nowrap;
        color: #666;

        a {   
            display: block;
            width: 100%;
            height: 100%;
            padding: 10px 30px;
            
            &:hover {
                font-weight: 500;
                color: $brand-color;
            }
        }

        &.on {
            a {
                color: $brand-color;
            }
        }

        + .aside-item {
            margin-top: 20px;
        }

        &:first-child {
            a {
                padding-top: 30px;
            }
        }

        &:last-child {
            a {
                padding-bottom: 30px;
            }
        }
    }   
}  
/********************************
aside END
********************************/