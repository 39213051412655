/********************************
component: tabs START
********************************/
nav.tabs {
    margin-bottom: 40px;
    border-bottom: 1px solid #ddd;

    li {
        flex: 1 1 0; 
    }

    a {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding-bottom: 20px;
        border-bottom: 3px solid transparent;
        text-align: center;
        color: $text-light;
       
        &.on,
        &:hover {
            border-bottom: 3px solid $brand-color;
            font-weight: 500;
            color: $brand-color;
        }
    }
}

/********************************
component: tabs END
********************************/