/********************************
고객센터 공지사항 START
********************************/
main.notice {
    padding-top: 60px;
    padding-bottom: 150px;

    .table-wrapper {
        margin-top: 30px;
        margin-bottom: 25px;
        border-top:2px solid $text-dark;
        border-bottom: 1px solid $text-dark;
    }

    table {
        font-size: 16px;
        text-align: center;
    }

    th {
        padding: 20px;
        border-bottom: 1px solid #ddd;   
    }

    td {
        padding: 20px;
        border-top: 1px solid #ddd;
    }
    
    tr:first-child {
        td {
            border-top: none;
        }
    }
}
/********************************
고객센터 공지사항 END
********************************/