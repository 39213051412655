/********************************
footer START
********************************/
footer {
    padding-top: 75px;
    padding-bottom: 130px;
    font-size: 16px;
    color: #999;
    background-color: #18161a;

    hr {
        width: 100%;
        height: 1px;
        margin-top: 39px;
        margin-bottom: 39px;
        background-color: #302e31;
    }

    nav {
        li {
            + li {
                margin-left: 20px;
            }
        }

        a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    strong {
        color: white; 
    }
}

address {
    strong {
        display: inline-block;
        margin-right: 10px;
    }

    li {
        + li {
            margin-left: 20px;
        }
    }

    p {
        margin-top: 20px;  
    }
}

.footer-dropdown { 
    position: relative;
    top: -12px;
    width: 200px;
    margin-left: auto;
    margin-bottom: auto;
    border-bottom: 1px solid white;

    .footer-toggle {
        display: block;
        width: 100%;
        height: 45px;
        line-height: 45px;

        @include bg-pos(100%, 20px, icon-footer-arrow);

        &.on {
            @include bg-pos(100%, 20px, icon-footer-arrow-south);
        }
    }

    .footer-dropdown-menu {
        display: none;
        z-index: 5;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 100%;
        width: 100%;
        background-color: #18161a;
    }

    .footer-dropdown-item {
        a { 
            display: block;
            width: 100%;
            height: 45px;
            line-height: 45px;
            color: inherit;
        }
    }
}
/********************************
footer END
********************************/