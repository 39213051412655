/********************************
체험관 START
********************************/
main.arremote {
    padding-top: 60px;
    padding-bottom: 150px;
    
    .search {
        margin-bottom: 30px;
    }

    .list {
        flex-wrap: wrap;
    }
    
    .list-item {
        margin-right: 30px;

        &:nth-child(3n) {
            margin-right: 0;
        }

        + .list-item {
            margin-bottom: 75px;
        }
    }

    figure {
        position: relative;

        img {
            width: 380px;
            height: 325px;
        }
    }

    figcaption {
        margin-top: 30px;

        p {
            font-size: 24px;
            color: $text-dark;
        }

        span {
            display: inline-block;
            margin-top: 25px;
            font-size: 16px;
            color: $text-light;
        }
    }

    .btn-play {
        z-index: 10;
        position: absolute;
        top: 45%;
        right: 0;
        left: 0;
        margin: 0 auto;

        @include bg(80, 80, comp-delper-img-play);
    }
}
/********************************
체험관 END
********************************/