@charset "UTF-8";
/********************************
reset START
********************************/
*, :before, :after {
  box-sizing: border-box;
}

html {
  line-height: 1;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body, p, h1, h2, h3, h4, h5, h6, ul, ol, li, dl, dt, dd, table, th, td, form, fieldset, legend, input, textarea, button, select, div, figure {
  margin: 0;
  padding: 0;
}

body, input, textarea, select, button, table {
  font-family: 'Noto Sans','Roboto','맑은 고딕','Malgun Gothic','돋움',Dotum,Helvetica,AppleGothic,Sans-serif;
  font-size: 16px;
  vertical-align: baseline;
  color: #666;
  word-break: keep-all;
  word-wrap: break-word;
}

img {
  max-width: 100%;
  border: 0;
  vertical-align: middle;
}

ul, li {
  list-style: none;
}

dd {
  word-break: keep-all;
  word-wrap: break-word;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-size: inherit;
}

em, address {
  font-style: normal;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

a:focus, a:active, a:hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

div:focus {
  outline: none;
}

header, hgroup, footer, section, article, aside, nav, figure, figcaption, main, details, menu {
  display: block;
}

table {
  border-collapse: collapse;
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;
  empty-cells: show;
}

tbody, tfoot, thead, tr, th, td {
  word-wrap: break-word;
}

caption, legend {
  display: block;
  position: absolute !important;
  left: -9999px !important;
  top: 0 !important;
  text-indent: 9999px !important;
  width: 0 !important;
  height: 0 !important;
  border: 0 !important;
  overflow: hidden !important;
  font-size: 1px;
  line-height: 0 !important;
  white-space: nowrap !important;
}

button {
  cursor: pointer;
  width: auto;
  overflow: visible;
  vertical-align: middle;
  background: none;
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;
  border: none;
  outline: none;
  box-sizing: border-box;
}

button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input {
  background: none;
  overflow: visible;
  vertical-align: middle;
  box-sizing: border-box;
}

input[type=text]::-ms-clear {
  display: none !important;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

input:focus,
select:focus {
  border-color: none;
  outline: none;
}

select::-ms-expand {
  display: none;
}

fieldset {
  border: none;
}

label {
  cursor: pointer;
}

hr {
  overflow: visible;
  height: 0;
  margin: 0;
  border: none;
  box-shadow: none;
}

i {
  font-style: normal;
}

/* placeholder */
::-webkit-input-placeholder {
  color: inherit !important;
}

::-moz-placeholder {
  color: inherit !important;
}

:-ms-input-placeholder {
  color: inherit !important;
}

:-moz-placeholder {
  color: inherit !important;
}

/* accessibility */
.blind {
  display: block;
  position: absolute;
  left: -9999px;
  text-indent: 9999px;
  width: 0;
  height: 0;
  border: 0;
  overflow: hidden;
  font-size: 1px;
  line-height: 0;
  white-space: nowrap;
}

/********************************
reset END
********************************/
/********************************
helper class START
********************************/
/* display */
.d-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.d-flex-align-center {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.ml-auto {
  margin-left: auto;
}

/* font-family Noboto*/
.font-lg {
  font-weight: 100;
}

.font-reg {
  font-weight: 300;
}

.font-md {
  font-weight: 500;
}

.font-bold {
  font-weight: 700;
}

/* font-family Roboto*/
.ro-font-lg {
  font-family: 'Roboto';
  font-weight: 100;
}

.ro-font-reg {
  font-family: 'Roboto';
  font-weight: 300;
}

.ro-font-md {
  font-family: 'Roboto';
  font-weight: 500;
}

.ro-font-bold {
  font-family: 'Roboto';
  font-weight: 700;
}

/* colors */
.text-dark {
  color: #333;
}

.text-brand {
  color: #5b00c1;
}

.text-white {
  color: white !important;
}

/* align */
.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

/*blocks*/
body {
  color: #666;
}

.w-inner {
  width: 1200px;
  margin: 0 auto;
}

.search {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.search .dropdown {
  width: 120px;
}

.search input[type="text"] {
  width: 380px;
  margin-left: 5px;
}

.search .btn-search {
  margin-left: 5px;
  width: 48px;
  height: 48px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/btn-search.png");
}

.icon-attached {
  display: inline-block;
  width: 18px;
  height: 19px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/icon-attached.png");
}

.fadeout {
  opacity: 0;
  -moz-transition: opacity 0.8s ease-in-out;
  -o-transition: opacity 0.8s ease-in-out;
  -webkit-transition: opacity 0.8s ease-in-out;
  transition: opacity 0.8s ease-in-out;
}

.fadein {
  opacity: 1;
}

/********************************
helper class END
********************************/
/********************************
font START
********************************/
@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 100;
  src: local("Noto Sans Light"), local("NotoSans-Light"), url(../fonts/NotoSansCJKkr-DemiLight.eot), url(../fonts/NotoSansCJKkr-DemiLight.eot?#iefix) format("embedded-opentype"), url(../fonts/NotoSansCJKkr-DemiLight.woff) format("woff");
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  src: local("Noto Sans Regular"), local("NotoSans-Regular"), url(../fonts/NotoSansCJKkr-Regular.eot), url(../fonts/NotoSansCJKkr-Regular.eot?#iefix) format("embedded-opentype"), url(../fonts/NotoSansCJKkr-Regular.woff) format("woff");
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  src: local("Noto Sans Medium"), local("NotoSans-Medium"), url(../fonts/NotoSansCJKkr-Medium.eot), url(../fonts/NotoSansCJKkr-Medium.eot?#iefix) format("embedded-opentype"), url(../fonts/NotoSansCJKkr-Medium.woff) format("woff");
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: local("Noto Sans Bold"), local("NotoSans-Bold"), url(../fonts/NotoSansCJKkr-Bold.eot), url(../fonts/NotoSansCJKkr-Bold.eot?#iefix) format("embedded-opentype"), url(../fonts/NotoSansCJKkr-Bold.woff) format("woff");
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Light"), local("Roboto-Light"), url(../fonts/Roboto-Light.eot), url(../fonts/Roboto-Light.eot?#iefix) format("embedded-opentype"), url(../fonts/Roboto-Light.woff) format("woff");
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Regular"), local("Roboto-Regular"), url(../fonts/Roboto-Regular.eot), url(../fonts/Roboto-Regular.eot?#iefix) format("embedded-opentype"), url(../fonts/Roboto-Regular.woff) format("woff");
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(../fonts/Roboto-Medium.eot), url(../fonts/Roboto-Medium.eot?#iefix) format("embedded-opentype"), url(../fonts/Roboto-Medium.woff) format("woff");
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(../fonts/Roboto-Bold.eot), url(../fonts/Roboto-Bold.eot?#iefix) format("embedded-opentype"), url(../fonts/Roboto-Bold.woff) format("woff");
}

/********************************
font END
********************************/
/********************************
layout START
********************************/
.wrapper {
  overflow-x: hidden;
  max-width: 1920px;
  min-width: 1200px;
  margin: 0 auto;
}

.submain {
  height: 400px;
}

.submain .submain-bar {
  height: 100%;
  color: white;
  background: url("../images/submain-aside-bg.png") 0 100% no-repeat;
}

.submain .submain-inner {
  height: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-direction: normal;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.submain header {
  margin-top: 175px;
}

.submain .en {
  font-size: 18px;
  line-height: 36px;
}

.submain .ko {
  font-size: 54px;
}

.submain.type-comp {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/comp-submain.png");
}

.submain.type-product {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/product-submain.png");
}

.submain.type-arremote {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/arremote-submain.png");
}

.submain.type-refs {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/refs-submain.png");
}

.submain.type-support {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/support-submain.png");
}

/********************************
layout END
********************************/
/********************************
navbar START
********************************/
.navbar {
  z-index: 15;
  position: absolute;
  left: 0;
  right: 0;
  max-width: 1920px;
  min-width: 1200px;
  min-height: 100px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin: 0 auto;
  background-color: transparent;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.4s;
  -moz-transition-duration: 0.4s;
  -o-transition-duration: 0.4s;
  transition-duration: 0.4s;
  -webkit-timing-function: ease-in-out;
  -moz-timing-function: ease-in-out;
  -o-timing-function: ease-in-out;
  timing-function: ease-in-out;
}

.navbar .logo {
  position: absolute;
  top: 30px;
  left: 40px;
  margin-left: 40px;
  width: 232px;
  height: 38px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/logo.png");
}

.navbar nav {
  padding: 0 204px;
}

.navbar .nav-item {
  position: relative;
  height: 100%;
  padding: 0 50px;
  align-self: center;
}

.navbar .nav-link {
  display: block;
  width: 100%;
  height: 100px;
  line-height: 99px;
  font-size: 18px;
}

.navbar .nav-link + .nav-link {
  margin-left: 95px;
}

.navbar .btn-hamburger {
  position: absolute;
  top: 38px;
  right: 30px;
  width: 32px;
  height: 22px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/btn-hamburger.png");
}

.navbar .sub {
  display: none;
}

.navbar.on {
  max-width: 1920px;
  min-width: 1200px;
  height: 170px !important;
  margin: 0 auto;
  background-color: white;
}

.navbar.on .logo {
  width: 232px;
  height: 38px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/logo-blue.png");
}

.navbar.on .nav-link {
  color: #333;
}

.navbar.on .nav-link.on, .navbar.on .nav-link:hover {
  border-bottom: 2px solid #5b00c1;
}

.navbar.on .nav-link.on + .sub, .navbar.on .nav-link:hover + .sub {
  display: block;
}

.navbar.on .sub {
  position: absolute;
  left: 50px;
  top: 100%;
}

.navbar.on .sub-item {
  height: 70px;
  line-height: 70px;
}

.navbar.on .sub-item + .sub-item {
  margin-left: 40px;
}

.navbar.on .sub-link {
  display: block;
  white-space: nowrap;
  color: #666;
}

.navbar.on .sub-link:hover {
  font-weight: 500;
  color: #5b00c1;
}

.navbar.on .btn-hamburger {
  width: 32px;
  height: 22px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/btn-hamburger-black.png");
}

.navbar.is-fixed {
  position: fixed !important;
  left: 0;
  right: 0;
  max-width: 1920px;
  min-width: 1200px;
  min-height: 100px;
  margin: 0 auto;
  background-color: white;
}

.navbar.is-fixed .logo {
  width: 232px;
  height: 38px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/logo-blue.png");
}

.navbar.is-fixed .nav-link {
  color: #333;
}

.navbar.is-fixed .sub {
  display: none;
}

.navbar.is-fixed .btn-hamburger {
  width: 24px;
  height: 24px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/btn-hamburger-black.png");
}

/********************************
navbar END
********************************/
/********************************
navbar modal START
********************************/
.modal-backdrop {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
}

.navbar-modal {
  z-index: 15;
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.navbar-modal-dialog {
  position: relative;
  max-width: 1920px;
  min-width: 1200px;
  height: 400px;
  margin: 0 auto;
  background-color: white;
}

.navbar-modal-dialog .header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #ddd;
}

.navbar-modal-dialog .logo-blue {
  display: inline-block;
  position: absolute;
  top: 30px;
  left: 40px;
  width: 232px;
  height: 38px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/logo-blue.png");
}

.navbar-modal-dialog nav {
  padding: 0 180px;
}

.navbar-modal-dialog nav ul {
  -webkit-box-pack: Space-between;
  -moz-box-pack: Space-between;
  -ms-flex-pack: Space-between;
  -webkit-justify-content: Space-between;
  justify-content: Space-between;
}

.navbar-modal-dialog .nav-item {
  position: relative;
  font-size: 18px;
}

.navbar-modal-dialog .nav-link {
  display: block;
  height: 99px;
  font-size: 18px;
  line-height: 100px;
  text-align: center;
  color: #333;
}

.navbar-modal-dialog .nav-link:hover {
  border-bottom: 2px solid #5b00c1;
}

.navbar-modal-dialog .sub {
  margin-top: 40px;
}

.navbar-modal-dialog .sub-item {
  flex: 1 1 0;
}

.navbar-modal-dialog .sub-item + .sub-item {
  margin-top: 20px;
}

.navbar-modal-dialog .sub-link {
  display: block;
  width: 100%;
  height: 100%;
  font-weight: 500;
  text-align: center;
  color: #666;
}

.navbar-modal-dialog .sub-link.on, .navbar-modal-dialog .sub-link:hover {
  color: #5b00c1;
}

.btn-hamburger-modal {
  position: absolute;
  top: 40px;
  right: 40px;
  width: 24px;
  height: 24px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/modal-btn-close.png");
}

/********************************
navbar modal END
********************************/
/********************************
aside START
********************************/
.aside {
  width: 485px;
  min-height: 70px;
  margin-top: auto;
  border-left: 1px solid #7529cb;
  background-color: #5b00c1;
}

.aside i {
  display: inline-block;
  width: 69px;
  height: 100%;
  border-left: 1px solid #7529cb;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/comp-submain-icon.png");
}

.aside .aside-dropdown {
  position: relative;
  width: 210px;
  height: auto;
  padding: 0 20px;
  border-left: 1px solid #7529cb;
}

.aside .aside-toggle {
  display: block;
  width: 100%;
  height: 70px;
  padding-right: 10px;
  font-size: 16px;
  line-height: 70px;
  text-align: left;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/icon-arrow-south-white.png");
}

.aside .aside-toggle.on {
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/icon-arrow-north-white.png");
}

.aside .aside-menu {
  display: none;
  z-index: 10;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: auto;
  border: 1px solid #dddddd;
  text-align: left;
  background-color: #fff;
}

.aside .aside-item {
  display: inline-block;
  width: 100%;
  font-size: 16px;
  white-space: nowrap;
  color: #666;
}

.aside .aside-item a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 30px;
}

.aside .aside-item a:hover {
  font-weight: 500;
  color: #5b00c1;
}

.aside .aside-item.on a {
  color: #5b00c1;
}

.aside .aside-item + .aside-item {
  margin-top: 20px;
}

.aside .aside-item:first-child a {
  padding-top: 30px;
}

.aside .aside-item:last-child a {
  padding-bottom: 30px;
}

/********************************
aside END
********************************/
/********************************
footer START
********************************/
footer {
  padding-top: 75px;
  padding-bottom: 130px;
  font-size: 16px;
  color: #999;
  background-color: #18161a;
}

footer hr {
  width: 100%;
  height: 1px;
  margin-top: 39px;
  margin-bottom: 39px;
  background-color: #302e31;
}

footer nav li + li {
  margin-left: 20px;
}

footer nav a {
  display: block;
  width: 100%;
  height: 100%;
}

footer strong {
  color: white;
}

address strong {
  display: inline-block;
  margin-right: 10px;
}

address li + li {
  margin-left: 20px;
}

address p {
  margin-top: 20px;
}

.footer-dropdown {
  position: relative;
  top: -12px;
  width: 200px;
  margin-left: auto;
  margin-bottom: auto;
  border-bottom: 1px solid white;
}

.footer-dropdown .footer-toggle {
  display: block;
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-position: 100% 20px;
  background-repeat: no-repeat;
  background-image: url("../images/icon-footer-arrow.png");
}

.footer-dropdown .footer-toggle.on {
  background-position: 100% 20px;
  background-repeat: no-repeat;
  background-image: url("../images/icon-footer-arrow-south.png");
}

.footer-dropdown .footer-dropdown-menu {
  display: none;
  z-index: 5;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  width: 100%;
  background-color: #18161a;
}

.footer-dropdown .footer-dropdown-item a {
  display: block;
  width: 100%;
  height: 45px;
  line-height: 45px;
  color: inherit;
}

/********************************
footer END
********************************/
/********************************
component: button START
********************************/
.btn-download {
  width: 200px;
  height: 60px;
  padding: 0 20px;
  border: 2px solid #29244b;
  font-weight: 500;
  line-height: 60px;
  text-align: center;
  color: #29244b;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.btn-download i {
  display: inline-block;
  margin-left: auto;
  width: 18px;
  height: 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/icon-download.png");
}

.btn {
  width: 160px;
  height: 60px;
  line-height: 60px;
}

.btn.type-list {
  color: white;
  background-color: #333;
}

.btn.type-primary {
  color: white;
  background-color: #5b00c1;
}

.btn.type-second {
  color: white;
  background-color: #999;
}

.btn.type-more {
  width: 200px;
  padding-left: 30px;
  padding-right: 25px;
  border: 2px solid #5b00c1;
  color: #5b00c1;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.btn.type-more i {
  margin-left: auto;
  width: 24px;
  height: 6px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/icon-arrow-more.png");
}

.btn.type-more.type-white {
  border-color: white;
  color: white;
}

.btn.type-more.type-white i {
  width: 24px;
  height: 6px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/icon-arrow-more-white.png");
}

/********************************
component: button END
********************************/
/********************************
component: dropdown START
********************************/
.dropdown {
  position: relative;
}

.dropdown .dropdown-toggle {
  padding-right: 10px;
  font-size: 16px;
  line-height: 48px;
  text-align: left;
  background-position: 95% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/icon-arrow-south.png");
}

.dropdown .dropdown-toggle.on {
  background-position: 95% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/icon-arrow-north.png");
}

.dropdown .dropdown-menu {
  display: none;
  z-index: 10;
  position: absolute;
  top: 100%;
  left: 0;
  float: left;
  min-width: 100%;
  margin-top: -1px;
  border: 1px solid #dddddd;
  text-align: left;
  background-color: #fff;
}

.dropdown .dropdown-item {
  display: block;
  width: 100%;
  height: 48px;
  padding: 0 1.5rem;
  clear: both;
  font-size: 16px;
  line-height: 48px;
  text-align: inherit;
  white-space: nowrap;
  color: #333;
  background-color: transparent;
}

/********************************
component: dropdown END
********************************/
/********************************
component: pagination START
********************************/
/* 공통 ui START */
.pagenation {
  font-size: 16px;
  text-align: center;
}

.pagenation a {
  display: inline-block;
  width: 35px;
  color: #999;
}

.pagenation a.on, .pagenation a:hover {
  color: #666;
}

.pagenation .btn {
  width: 20px;
}

.pagenation .btn-prev {
  margin-right: 20px;
  width: 8px;
  height: 12px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/btn-prev.png");
}

.pagenation .btn-prev-10 {
  width: 14px;
  height: 12px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/btn-prev-10.png");
}

.pagenation .btn-next {
  margin-left: 20px;
  transform: rotate(180deg);
  width: 8px;
  height: 12px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/btn-prev.png");
}

.pagenation .btn-next-10 {
  transform: rotate(180deg);
  width: 14px;
  height: 12px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/btn-prev-10.png");
}

.pagenation button + button {
  margin-left: 15px;
}

/********************************
component: pagination END
********************************/
/********************************
component: form-control START
********************************/
.form-control {
  display: block;
  width: 100%;
  height: 48px;
  padding: 0 20px;
  font-size: 16px;
  line-height: 48px;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dddddd;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

/********************************
component: form-control END
********************************/
/********************************
component: input file type START
********************************/
.input-file {
  display: inline-block;
  position: relative;
}

.input-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;
}

.input-file label {
  display: inline-block;
  min-width: 120px;
  height: 48px;
  border: 2px solid  #29244b;
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  text-align: center;
  vertical-align: middle;
  color: #29244b;
}

.input-file input[type="text"] {
  min-width: 290px;
  height: 48px;
  border: 1px solid #f5f5f5;
  line-height: 48px;
  text-indent: 20px;
  background: #f5f5f5;
}

.input-file input[type="text"] + label {
  margin-left: 6px;
}

.input-file .file-focus {
  outline: 1px dotted #d2310e;
}

/********************************
component: input file type END
********************************/
/********************************
component: tabs START
********************************/
nav.tabs {
  margin-bottom: 40px;
  border-bottom: 1px solid #ddd;
}

nav.tabs li {
  flex: 1 1 0;
}

nav.tabs a {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding-bottom: 20px;
  border-bottom: 3px solid transparent;
  text-align: center;
  color: #999;
}

nav.tabs a.on, nav.tabs a:hover {
  border-bottom: 3px solid #5b00c1;
  font-weight: 500;
  color: #5b00c1;
}

/********************************
component: tabs END
********************************/
/********************************
component: modal START
********************************/
.modal-backdrop {
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
}

.modal {
  z-index: 15;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.modal-dialog {
  position: relative;
  max-width: 580px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.modal-content {
  width: 100%;
  background-color: white;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-direction: normal;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.modal-header {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.modal-header .close {
  margin-top: 30px;
  margin-right: 30px;
  margin-left: auto;
  width: 25px;
  height: 25px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/modal-btn-close.png");
}

.modal-body {
  padding: 0 30px;
  text-align: center;
}

.modal-body i {
  display: inline-block;
  margin-bottom: 25px;
  width: 80px;
  height: 80px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/modal-img-1.png");
}

.modal-body span {
  display: block;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
}

.modal-body strong {
  color: #5b00c1;
}

.modal-body p {
  margin-top: 25px;
  font-size: 16px;
  line-height: 25px;
  color: #666;
}

.modal-footer {
  margin-top: 60px;
}

.modal-footer .btn-primary {
  width: 100%;
  height: 60px;
  font-size: 16px;
  text-align: center;
  color: white;
  background-color: #5b00c1;
}

/********************************
component: modal END
********************************/
/********************************
회사 개요 START
********************************/
main.overview {
  padding-top: 100px;
  padding-bottom: 150px;
  background-color: #f8f8f8;
}

main.overview .content {
  height: 640px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/comp_overview_img_1.png");
}

main.overview .w-inner {
  height: 640px;
}

main.overview .paragraph {
  height: 427px;
  margin-top: auto;
  padding-top: 70px;
  padding-left: 70px;
  padding-right: 65px;
  color: #333;
  background-color: white;
}

main.overview .title {
  font-size: 28px;
  line-height: 46px;
  font-weight: 500;
}

main.overview .text {
  padding-top: 30px;
  font-size: 18px;
  line-height: 2;
  font-weight: 100;
}

main.overview strong {
  color: #5b00c1;
}

main.overview .slider-wrapper {
  position: relative;
  margin-top: 145px;
  width: 1560px;
  margin-left: 360px;
}

main.overview .slider-wrapper .paragraph {
  z-index: 10;
  position: absolute;
  left: 0;
  bottom: 0;
}

main.overview .slider-wrapper img {
  margin-left: 360px;
}

/********************************
회사 개요 END
********************************/
/********************************
회사 연혁 START
********************************/
main.histroy {
  padding-top: 90px;
  padding-bottom: 150px;
  background-color: #f8f8f8;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/comp_history_img_1.png");
}

main.histroy .list {
  padding-left: 690px;
  color: #666;
}

main.histroy .list-item {
  font-size: 18px;
  font-weight: 100;
}

main.histroy .list-item + .list-item {
  margin-top: 45px;
}

main.histroy .list-content li + li {
  margin-top: 20px;
}

main.histroy strong {
  display: block;
  margin-bottom: 30px;
  font-size: 36px;
  font-family: 'Roboto';
  font-weight: 700;
  color: #333;
}

main.histroy span {
  display: inline-block;
  margin-right: 20px;
  color: #333;
}

/********************************
회사 연혁 END
********************************/
/********************************
파트너 START
********************************/
main.partner {
  padding-top: 100px;
  padding-bottom: 150px;
  font-family: 'Roboto';
  font-weight: 500;
}

main.partner .w-innder {
  background-color: salmon;
}

main.partner .tabs {
  margin-bottom: 80px;
}

main.partner .tabs li {
  border: 1px solid #dddddd;
  line-height: 60px;
  text-align: center;
  flex: 1 1 0;
}

main.partner .tabs li + li {
  margin-left: -1px;
}

main.partner .tabs li a {
  display: block;
  width: 100%;
  height: 100%;
  color: #666;
}

main.partner .tabs li a.on, main.partner .tabs li a:hover {
  border: 1px solid #29244b;
  color: white;
  background-color: #29244b;
}

main.partner .tabs a {
  width: 100%;
  height: 100%;
  font-size: 18px;
  font-weight: 300;
  color: #666;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

main.partner header {
  margin-top: 70px;
  margin-bottom: 25px;
  font-size: 30px;
  font-family: Roboto;
  font-weight: 500;
  color: #333;
}

main.partner .list {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

main.partner .list li {
  min-width: 185px;
  height: 100px;
  margin-right: 18px;
  margin-bottom: 18px;
  border: 1px solid #dddddd;
}

main.partner .list li:nth-child(6n) {
  margin-right: 0;
}

main.partner .list a {
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

/********************************
파트너 END
********************************/
/********************************
회사 위치 START
********************************/
main.location {
  padding-top: 100px;
  padding-bottom: 140px;
  font-size: 18px;
  color: #333;
}

main.location figure {
  width: 100%;
  height: 700px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/comp-location-img-1.png");
}

main.location .list-info {
  margin-top: 80px;
}

main.location .list-info li + li {
  margin-top: 20px;
}

main.location .list-info h2 {
  width: 255px;
  margin-right: 30px;
  border-right: 1px solid #dddddd;
  font-size: 38px;
  font-weight: 700;
}

main.location .list-info strong {
  display: inline-block;
  width: 115px;
  font-weight: 700;
}

main.location hr {
  width: 100%;
  height: 5px;
  margin-top: 75px;
  background-color: #dddddd;
}

main.location .list-trans h2 {
  width: 230px;
  font-size: 24px;
  font-weight: 700;
}

main.location .list-trans > li {
  padding: 75px 0;
  border-top: 1px solid #e5e5e5;
}

main.location .list-trans-contents li + li {
  margin-top: 10px;
}

main.location .icon-trans {
  display: inline-block;
  width: 22px;
  height: 22px;
  margin-right: 7px;
  text-align: center;
  color: white;
  border-radius: 100%;
}

main.location .icon-trans:before {
  display: inline-block;
  margin: 0 auto;
}

main.location .line-5 {
  background-color: #8445ce;
}

main.location .line-5:before {
  content: '5';
  color: white;
}

main.location .bus-blue {
  background-color: #8445ce;
}

main.location .bus-blue:before {
  content: ' B';
  text-align: center;
  color: white;
}

main.location .bus-green {
  background-color: #5bb025;
}

main.location .bus-green:before {
  content: ' B';
  text-align: center;
  color: white;
}

main.location .bus-red {
  background-color: #f72f08;
}

main.location .bus-red:before {
  content: ' G';
  text-align: center;
  color: white;
}

/********************************
회사 위치 END
********************************/
/********************************
납품실적 START
********************************/
main.delper {
  padding-top: 100px;
  padding-bottom: 150px;
}

main.delper .caption {
  margin-top: 88px;
  margin-left: 40px;
}

main.delper .caption h2 {
  font-size: 28px;
  color: #333;
}

main.delper .caption p {
  margin-top: 30px;
  font-size: 18px;
  line-height: 28px;
}

main.delper .list {
  flex-wrap: wrap;
  margin-top: 70px;
}

main.delper .list-item {
  margin-right: 30px;
}

main.delper .list-item:nth-child(3n) {
  margin-right: 0;
}

main.delper .list-item + .list-item {
  margin-bottom: 75px;
}

main.delper figure {
  position: relative;
}

main.delper figure img {
  width: 380px;
  height: 325px;
}

main.delper figcaption {
  margin-top: 30px;
}

main.delper figcaption p {
  font-size: 24px;
  color: #333;
}

main.delper figcaption span {
  display: inline-block;
  margin-top: 25px;
  font-size: 16px;
  color: #999;
}

main.delper .btn-play {
  z-index: 10;
  position: absolute;
  top: 45%;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/comp-delper-img-play.png");
}

main.delper .slider-wrapper {
  position: relative;
}

main.delper .slider-wrapper .txt-performance {
  z-index: 5;
  position: absolute;
  top: -24px;
  left: -366px;
  font-size: 150px;
  letter-spacing: -6px;
  text-transform: uppercase;
  opacity: 0.6;
  color: #ededed;
}

/********************************
납품실적 END
********************************/
/********************************
납품실적 보기 START
********************************/
main.delperview {
  padding-top: 100px;
  padding-bottom: 150px;
}

main.delperview .slider {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

main.delperview figure {
  position: relative;
}

main.delperview figcaption {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  padding: 0 40px;
  background-color: rgba(0, 0, 0, 0.6);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

main.delperview .list-caption {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

main.delperview .caption-title {
  margin-right: 80px;
  font-size: 28px;
  font-weight: 500;
  color: white;
}

main.delperview .caption-text {
  font-size: 18px;
  font-weight: 100;
  color: white;
}

main.delperview .caption-text + .caption-text {
  margin-left: 20px;
}

main.delperview .list-item {
  padding: 0 15px;
}

main.delperview .list-img {
  width: 250px;
  height: 180px;
}

main.delperview .btn-prev {
  margin-right: 20px;
  width: 18px;
  height: 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/btn-prev-big.png");
}

main.delperview .btn-next {
  margin-left: 20px;
  transform: rotate(-180deg);
  width: 18px;
  height: 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/btn-prev-big.png");
}

main.delperview .divider {
  width: 100%;
  height: 1px;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: #ddd;
}

main.delperview .divider.type-dark {
  background-color: #333;
}

main.delperview .selected-img {
  width: 1200px;
  height: 600px;
}

/********************************
납품실적 보기 END
********************************/
/********************************
제품소개 START
********************************/
main.product {
  padding-top: 80px;
  padding-bottom: 120px;
}

main.product .prd-pic {
  height: 600px;
  margin-bottom: 40px;
  background-color: #f3f2f8;
}

main.product .prd-inner {
  position: relative;
  height: 600px;
}

main.product .prd-img {
  width: 404px;
  height: 437px;
  margin-left: auto;
}

main.product .prd-title {
  margin-top: 55px;
  margin-bottom: auto;
}

main.product .prd-title span {
  display: inline-block;
  margin-bottom: 20px;
  border-bottom: 1px solid #5b00c1;
  font-size: 18px;
  font-family: 'Roboto';
  font-weight: 500;
}

main.product .prd-title h2 {
  font-size: 54px;
  line-height: 72px;
}

main.product .sumnail-list {
  position: absolute;
  left: 0;
  bottom: 60px;
}

main.product .sumnail-item {
  position: relative;
  width: 120px;
  height: 79px;
  border: 1px solid #ddd;
}

main.product .sumnail-item + .sumnail-item {
  margin-left: 10px;
}

main.product .sumnail-item.on:before, main.product .sumnail-item:hover:before {
  content: '';
  position: absolute;
  left: -1px;
  top: -1px;
  width: 120px;
  height: 79px;
  border: 2px solid #5b00c1;
  opacity: 0.7;
  background-color: white;
}

main.product .sumnail-link {
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

main.product .sumnail-img {
  width: 55px;
  height: 60px;
}

main.product dl + dl {
  border-top: 1px solid #ddd;
}

main.product .title,
main.product .content {
  padding: 40px 0;
}

main.product .title {
  min-width: 300px;
  font-size: 24px;
  font-family: 'Roboto';
  font-weight: 500;
  color: #333;
}

main.product .content.designation dt {
  margin-right: 20px;
}

main.product .content.designation p {
  font-size: 18px;
  color: #333;
}

main.product .content.designation dd + dt {
  margin-left: 90px;
}

main.product h3 {
  margin-top: 20px;
  font-size: 30px;
  font-family: 'Roboto';
  font-weight: 500;
  color: #333;
}

main.product .overview-item {
  padding-left: 18px;
  font-size: 18px;
  line-height: 36px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

main.product .overview-item:before {
  content: '';
  display: inline-block;
  margin-right: 12px;
  width: 7px;
  height: 8px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/icon-dot.png");
}

main.product .btn-download + .btn-download {
  margin-left: 10px;
}

main.product table {
  text-align: left;
}

main.product th {
  font-weight: 500;
  color: #333;
}

main.product th, main.product td {
  padding: 20px 0;
  border-bottom: 1px solid #ddd;
}

main.product col:nth-child(1) {
  width: 150px;
}

main.product tr:last-child th, main.product tr:last-child td {
  border-bottom: none;
}

/********************************
제품소개 END
********************************/
/********************************
체험관 START
********************************/
main.arremote {
  padding-top: 60px;
  padding-bottom: 150px;
}

main.arremote .search {
  margin-bottom: 30px;
}

main.arremote .list {
  flex-wrap: wrap;
}

main.arremote .list-item {
  margin-right: 30px;
}

main.arremote .list-item:nth-child(3n) {
  margin-right: 0;
}

main.arremote .list-item + .list-item {
  margin-bottom: 75px;
}

main.arremote figure {
  position: relative;
}

main.arremote figure img {
  width: 380px;
  height: 325px;
}

main.arremote figcaption {
  margin-top: 30px;
}

main.arremote figcaption p {
  font-size: 24px;
  color: #333;
}

main.arremote figcaption span {
  display: inline-block;
  margin-top: 25px;
  font-size: 16px;
  color: #999;
}

main.arremote .btn-play {
  z-index: 10;
  position: absolute;
  top: 45%;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 80px;
  height: 80px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/comp-delper-img-play.png");
}

/********************************
체험관 END
********************************/
/********************************
체험관 보기 START
********************************/
main.arremoteview {
  padding-top: 60px;
  padding-bottom: 150px;
}

main.arremoteview dl {
  margin-bottom: 40px;
  border-top: 2px solid #333;
  border-bottom: 1px solid #333;
}

main.arremoteview dt {
  padding: 45px 30px;
  border-bottom: 1px solid #dddddd;
}

main.arremoteview h2 {
  font-size: 24px;
  color: #333;
}

main.arremoteview .yymmdd {
  font-size: 16px;
}

main.arremoteview .yymmdd-title {
  display: inline-block;
  margin-right: 20px;
  color: #333;
}

main.arremoteview .yymmdd-text {
  color: #999;
}

main.arremoteview dd {
  padding: 60px 30px;
  font-size: 16px;
  line-height: 32px;
}

/********************************
체험관 보기 END
********************************/
/********************************
관련자료 START
********************************/
main.refs {
  padding-top: 60px;
  padding-bottom: 140px;
}

main.refs .search {
  margin-bottom: 30px;
}

main.refs .list {
  margin-bottom: 30px;
  border-top: 2px solid #333;
  border-bottom: 1px solid #333;
}

main.refs .list-item {
  padding: 20px;
}

main.refs .list-item:first-child {
  border-top: none;
}

main.refs .list-item + .list-item {
  border-top: 1px solid #ddd;
}

main.refs img {
  width: 160px;
  height: 120px;
}

main.refs .content {
  padding: 0 30px;
}

main.refs h2 {
  font-size: 24px;
  color: #333;
}

main.refs p {
  margin-top: 20px;
  font-size: 18px;
}

main.refs .btn-download {
  width: 150px;
  height: 48px;
  line-height: 48px;
}

main.refs .btn-download + .btn-download {
  margin-left: 6px;
}

/********************************
관련자료 END
********************************/
/********************************
고객센터 공지사항 START
********************************/
main.notice {
  padding-top: 60px;
  padding-bottom: 150px;
}

main.notice .table-wrapper {
  margin-top: 30px;
  margin-bottom: 25px;
  border-top: 2px solid #333;
  border-bottom: 1px solid #333;
}

main.notice table {
  font-size: 16px;
  text-align: center;
}

main.notice th {
  padding: 20px;
  border-bottom: 1px solid #ddd;
}

main.notice td {
  padding: 20px;
  border-top: 1px solid #ddd;
}

main.notice tr:first-child td {
  border-top: none;
}

/********************************
고객센터 공지사항 END
********************************/
/********************************
공지 상세 START
********************************/
main.noticeview {
  padding-top: 60px;
  padding-bottom: 150px;
}

main.noticeview dl {
  margin-bottom: 40px;
  border-top: 2px solid #333;
  border-bottom: 1px solid #333;
}

main.noticeview dt {
  padding: 45px 30px;
  border-bottom: 1px solid #dddddd;
}

main.noticeview h2 {
  font-size: 24px;
  color: #333;
}

main.noticeview .yymmdd {
  font-size: 16px;
}

main.noticeview .yymmdd-title {
  display: inline-block;
  margin-right: 20px;
  color: #333;
}

main.noticeview .yymmdd-text {
  color: #999;
}

main.noticeview dd {
  padding-left: 20px;
  padding-right: 20px;
}

main.noticeview dd.content {
  padding: 60px 30px;
  font-size: 16px;
  line-height: 32px;
}

main.noticeview dd.attached {
  line-height: 50px;
}

main.noticeview dd.attached i {
  margin-right: 10px;
}

main.noticeview dd.attached a {
  text-decoration: underline;
}

main.noticeview .nav-wrapper {
  border-top: 1px solid #ddd;
}

main.noticeview .link-prev,
main.noticeview .link-next {
  height: 67px;
  line-height: 67px;
}

main.noticeview .link-prev strong,
main.noticeview .link-next strong {
  width: 130px;
}

main.noticeview .link-prev i,
main.noticeview .link-next i {
  display: inline-block;
  margin-left: auto;
}

main.noticeview .link-prev h3,
main.noticeview .link-next h3 {
  margin-left: 70px;
}

main.noticeview .link-prev i {
  width: 8px;
  height: 4px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/icon-nav-arrow.png");
}

main.noticeview .link-prev + .link-next {
  border-top: 1px solid #ddd;
}

main.noticeview .link-next i {
  transform: rotate(180deg);
  width: 8px;
  height: 4px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/icon-nav-arrow.png");
}

/********************************
공지 상세 END
********************************/
/********************************
faq START
********************************/
main.faq {
  padding-top: 80px;
  padding-bottom: 150px;
}

main.faq dl {
  margin-top: 30px;
  margin-bottom: 30px;
  border-top: 2px solid #333;
  border-bottom: 1px solid #333;
}

main.faq dt {
  width: 100%;
  padding: 30px 30px 30px 80px;
  border-bottom: 1px solid #ddd;
  background-position: 30px 50%;
  background-repeat: no-repeat;
  background-image: url("../images/faq-txt-q.png");
}

main.faq dt:last-of-type {
  border-bottom: none;
}

main.faq dt.open button {
  width: 18px;
  height: 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/faq-icon-minus.png");
}

main.faq dt.open + dd {
  display: block;
  border-bottom: none;
}

main.faq dt button {
  width: 18px;
  height: 18px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/faq-icon-plus.png");
}

main.faq strong {
  display: inline-block;
  width: 100px;
  color: #333;
}

main.faq dd {
  display: none;
  padding: 30px 30px 30px 80px;
  line-height: 30px;
  background-color: #f4f4f4;
  background-position: 30px 40px;
  background-repeat: no-repeat;
  background-image: url("../images/faq-txt-a.png");
}

main.faq dd:last-child {
  border-bottom: none;
}

/********************************
faq END
********************************/
/********************************
고객센터 질문하기 START
********************************/
main.inquiry {
  padding-top: 60px;
  padding-bottom: 150px;
}

main.inquiry .table-wrapper {
  border-top: 2px solid #333;
  border-bottom: 1px solid #333;
}

main.inquiry table {
  table-layout: auto;
}

main.inquiry th {
  font-weight: 500;
  color: #333;
}

main.inquiry th,
main.inquiry td {
  padding: 10px 30px;
  border-bottom: 1px solid #ddd;
  text-align: left;
}

main.inquiry input,
main.inquiry textarea {
  border: none;
  background-color: #f4f4f4;
}

main.inquiry textarea {
  height: 250px;
}

main.inquiry .attention {
  margin-top: 20px;
  font-size: 14px;
  line-height: 24px;
}

main.inquiry .form-btn-group {
  margin-top: 40px;
}

main.inquiry .form-btn-group .btn + .btn {
  margin-left: 12px;
}

/********************************
고객센터 질문하기 END
********************************/
/********************************
main START
********************************/
.main-wrapper {
  position: relative;
  left: 0;
  top: 0;
  max-width: 1920px;
  width: 1920px;
  min-width: 1200px;
  margin: 0 auto;
}

.main-wrapper .btn.type-more:hover span,
.main-wrapper .btn.type-more:hover i {
  position: relative;
  z-index: 5;
}

.main-wrapper .btn.type-more:hover span {
  font-family: 'Roboto';
  font-weight: 500;
  color: white;
}

.main-wrapper .btn.type-more:hover i {
  width: 24px;
  height: 6px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/icon-arrow-more-white.png");
}

.main-wrapper .btn.type-more:hover:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 57px;
  border-color: #5b00c1;
  background-color: #5b00c1;
  -webkit-animation: animate 400ms cubic-bezier(0.4, 0, 0.2, 1);
  -moz-animation: animate 400ms cubic-bezier(0.4, 0, 0.2, 1);
  -o-animation: animate 400ms cubic-bezier(0.4, 0, 0.2, 1);
  animation: animate 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes animate {
  from {
    width: 0;
  }
}

.main-wrapper .btn.type-more.type-white:hover {
  border-color: white;
}

.main-wrapper .btn.type-more.type-white:hover:before {
  border-color: white;
  background-color: white;
}

.main-wrapper .btn.type-more.type-white:hover span {
  color: #5b00c1 !important;
}

.main-wrapper .btn.type-more.type-white:hover i {
  width: 24px;
  height: 6px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/icon-arrow-more.png");
}

.slider2 {
  overflow: hidden;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.7s;
  -moz-transition-duration: 0.7s;
  -o-transition-duration: 0.7s;
  transition-duration: 0.7s;
  -webkit-timing-function: ease-in-out;
  -moz-timing-function: ease-in-out;
  -o-timing-function: ease-in-out;
  timing-function: ease-in-out;
}

section.main-slider {
  position: relative;
  /* 슬라이더 효과 START*/
  /* 슬라이더 효과 END*/
}

section.main-slider .navbar {
  z-index: 10;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  margin: 0 auto;
  border-bottom: none;
}

section.main-slider .navbar a {
  color: white;
}

section.main-slider .slider-caption {
  z-index: 5;
  position: absolute;
  top: 305px;
  left: 355px;
  color: white;
}

section.main-slider .en {
  font-size: 64px;
  line-height: 88px;
}

section.main-slider .en-prx {
  display: inline-block;
  position: relative;
  opacity: 0;
  -webkit-transform: translateX(-20px);
  -moz-transform: translateX(-20px);
  -ms-transform: translateX(-20px);
  transform: translateX(-20px);
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
  -webkit-timing-function: ease-in-out;
  -moz-timing-function: ease-in-out;
  -o-timing-function: ease-in-out;
  timing-function: ease-in-out;
}

section.main-slider .en-prx.on {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

section.main-slider .ko {
  position: relative;
  margin-top: 40px;
  font-size: 18px;
  line-height: 32px;
}

section.main-slider .btn.type-more.type-white {
  margin-top: 75px;
}

section.main-slider .ko-prx {
  opacity: 0;
  -webkit-transform: translateY(80px);
  -moz-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.6s;
  -moz-transition-duration: 0.6s;
  -o-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -webkit-timing-function: ease-in-out;
  -moz-timing-function: ease-in-out;
  -o-timing-function: ease-in-out;
  timing-function: ease-in-out;
}

section.main-slider .ko-prx.on {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

section.main-slider .btn-prx {
  opacity: 0;
  -webkit-transform: translateY(80px);
  -moz-transform: translateY(80px);
  -ms-transform: translateY(80px);
  transform: translateY(80px);
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
  -webkit-timing-function: ease-in-out;
  -moz-timing-function: ease-in-out;
  -o-timing-function: ease-in-out;
  timing-function: ease-in-out;
}

section.main-slider .btn-prx.on {
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

section.main-slider .slider2-wrapper {
  perspective: 500;
}

section.main-slider .slider2-wrapper .slider-track {
  -webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
  transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
}

section.main-slider .main-pic {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 1920px;
  background-size: cover;
  background-color: black;
}

section.main-slider .main-pic img {
  min-width: 100%;
  -webkit-transition: all 1s;
  transition: all 1s;
  -webkit-animation: cssAnimation 8s 1 ease-in-out;
  animation: cssAnimation 8s 1 ease-in-out;
}

section.main-slider .slick-current,
section.main-slider .slick-active {
  transform: perspective(500px) rotate(0.001deg);
}

section.main-slider .slick-current img,
section.main-slider .slick-active img {
  -webkit-animation: cssAnimation 8s 1 ease-in-out;
  animation: cssAnimation 8s 1 ease-in-out;
}

section.main-slider .cssAnimationEffect {
  -webkit-animation: cssAnimation 8s 1 ease-in-out;
  animation: cssAnimation 8s 1 ease-in-out;
}

@keyframes cssAnimation {
  0% {
    -webkit-transform: scale(1.2) translate(0px);
    transform: scale(1.2) translate(0px);
  }
  100% {
    -webkit-transform: scale(1) translate(0px);
    transform: scale(1) translate(0px);
  }
}

@-webkit-keyframes cssAnimation {
  0% {
    -webkit-transform: scale(1.2) translate(0px);
    transform: scale(1.2) translate(0px);
  }
  100% {
    -webkit-transform: scale(1) translate(0px);
    transform: scale(1) translate(0px);
  }
}

section.main-slider .main-pic-1 {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/main-slider-1.png");
}

section.main-slider .main-pic-2 {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/main-slider-2.png");
}

section.main-slider .main-pic-3 {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/main-slider-3.png");
}

section.main-biz {
  position: relative;
  height: 1800px;
  border: 1px solid transparent;
}

section.main-biz .en {
  text-transform: uppercase;
}

section.main-biz .pic {
  width: 540px;
  height: 680px;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
  -webkit-timing-function: ease-in-out;
  -moz-timing-function: ease-in-out;
  -o-timing-function: ease-in-out;
  timing-function: ease-in-out;
}

section.main-biz .pic.pic-1 {
  margin-right: 100px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/main-biz-pic-1.png");
}

section.main-biz .pic.pic-2 {
  position: relative;
  top: -40px;
  margin-left: auto;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/main-biz-pic-2.png");
}

section.main-biz .txt {
  position: absolute;
}

section.main-biz .txt.text-1 {
  top: 130px;
  left: 0;
}

section.main-biz .txt.text-2 {
  bottom: 129px;
  right: 0;
}

section.main-biz ul {
  margin-top: 240px;
}

section.main-biz > li {
  position: relative;
  height: 680px;
}

section.main-biz > li:first-child .content {
  margin-left: 100px;
}

section.main-biz > li:last-child {
  margin-right: auto;
}

section.main-biz > li:last-child .content {
  margin-left: 102px;
}

section.main-biz strong {
  display: block;
  margin-top: 113px;
  color: #333;
}

section.main-biz h2 {
  margin-top: 30px;
  font-size: 54px;
  line-height: 74px;
  color: #333;
}

section.main-biz p {
  margin-top: 40px;
  font-size: 18px;
  line-height: 30px;
}

section.main-biz button {
  margin-top: 54px;
}

section.main-biz .en-prx-2,
section.main-biz .en-prx {
  opacity: 0;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
  -webkit-timing-function: ease-in-out;
  -moz-timing-function: ease-in-out;
  -o-timing-function: ease-in-out;
  timing-function: ease-in-out;
}

section.main-biz .en-prx-2.on,
section.main-biz .en-prx.on {
  opacity: 1;
}

section.main-biz .ko-prx {
  opacity: 0;
  -webkit-transform: translateX(100px);
  -moz-transform: translateX(100px);
  -ms-transform: translateX(100px);
  transform: translateX(100px);
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
  -webkit-timing-function: ease-in-out;
  -moz-timing-function: ease-in-out;
  -o-timing-function: ease-in-out;
  timing-function: ease-in-out;
}

section.main-biz .ko-prx.on {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

section.main-biz .btn-prx {
  opacity: 0;
  -webkit-transform: translateX(100px);
  -moz-transform: translateX(100px);
  -ms-transform: translateX(100px);
  transform: translateX(100px);
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
  -webkit-timing-function: ease-in-out;
  -moz-timing-function: ease-in-out;
  -o-timing-function: ease-in-out;
  timing-function: ease-in-out;
}

section.main-biz .btn-prx.on {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

section.main-biz .ko-prx-2 {
  opacity: 0;
  -webkit-transform: translateX(-100px);
  -moz-transform: translateX(-100px);
  -ms-transform: translateX(-100px);
  transform: translateX(-100px);
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
  -webkit-timing-function: ease-in-out;
  -moz-timing-function: ease-in-out;
  -o-timing-function: ease-in-out;
  timing-function: ease-in-out;
}

section.main-biz .ko-prx-2.on {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

section.main-biz .btn-prx-2 {
  opacity: 0;
  -webkit-transform: translateX(-100px);
  -moz-transform: translateX(-100px);
  -ms-transform: translateX(-100px);
  transform: translateX(-100px);
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
  -webkit-timing-function: ease-in-out;
  -moz-timing-function: ease-in-out;
  -o-timing-function: ease-in-out;
  timing-function: ease-in-out;
}

section.main-biz .btn-prx-2.on {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

section.main-client {
  position: relative;
  height: 840px;
  background-color: #5b00c1;
}

section.main-client .en {
  text-transform: uppercase;
}

section.main-client .text-1 {
  position: absolute;
  left: 0;
  top: 0;
}

section.main-client .cli-inner {
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-direction: normal;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

section.main-client header {
  margin-top: 120px;
  text-align: center;
  color: white;
}

section.main-client .en {
  font-size: 16px;
}

section.main-client .ko {
  margin-top: 20px;
  font-size: 54px;
}

section.main-client ul {
  margin-top: 50px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

section.main-client li {
  width: 283px;
  height: 200px;
  margin-right: 22px;
  margin-bottom: 22px;
  background-color: white;
}

section.main-client li:nth-child(4n) {
  margin-right: 0;
}

section.main-client a {
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

section.main-client .cli-item-prx {
  opacity: 0;
  -webkit-transform: translateX(-20px);
  -moz-transform: translateX(-20px);
  -ms-transform: translateX(-20px);
  transform: translateX(-20px);
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
  -webkit-timing-function: ease-in-out;
  -moz-timing-function: ease-in-out;
  -o-timing-function: ease-in-out;
  timing-function: ease-in-out;
}

section.main-client .cli-item-prx.on {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

section.main-case {
  position: relative;
  height: 890px;
  padding-top: 240px;
}

section.main-case .en {
  font-family: 'Roboto';
  font-weight: 500;
  text-transform: uppercase;
}

section.main-case .ko {
  font-weight: 500;
}

section.main-case .text-1 {
  position: absolute;
  right: 0;
  top: 130px;
}

section.main-case header {
  margin-left: 360px;
  margin-right: 100px;
}

section.main-case .en {
  font-size: 16px;
  color: #333;
}

section.main-case .ko {
  margin-top: 20px;
  font-size: 54px;
  line-height: 74px;
  color: #333;
}

section.main-inquiry {
  height: 320px;
  color: white;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/main-inquiry-bg.png");
}

section.main-inquiry .en {
  font-size: 16px;
  text-transform: uppercase;
}

section.main-inquiry .ko {
  margin-top: 20px;
  font-size: 54px;
}

.indigator {
  z-index: 10;
  position: fixed;
  top: 50%;
  width: 170px;
  height: 100vh;
}

.indigator li {
  position: relative;
  padding-left: 80px;
  text-transform: uppercase;
  color: #333;
  cursor: pointer;
}

.indigator li + li {
  margin-top: 19px;
}

.indigator li.on, .indigator li:hover {
  color: #5b00c1;
}

.indigator li.on:before, .indigator li:hover:before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  top: 8px;
  width: 60px;
  max-width: 60px;
  height: 2px;
  background-color: #5b00c1;
  -webkit-animation: animate 400ms ease-in-out forwards;
  -moz-animation: animate 400ms ease-in-out forwards;
  -o-animation: animate 400ms ease-in-out forwards;
  animation: animate 400ms ease-in-out forwards;
}

.indigator li.on2 {
  color: #b783f1;
}

.indigator li.cli-prx.on2 {
  color: white;
}

.indigator li.cli-prx.on2:before {
  color: white;
  background-color: white;
}

.indigator-prx {
  opacity: 0;
  -webkit-transform: translateX(-200px);
  -moz-transform: translateX(-200px);
  -ms-transform: translateX(-200px);
  transform: translateX(-200px);
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
  -webkit-timing-function: ease-in-out;
  -moz-timing-function: ease-in-out;
  -o-timing-function: ease-in-out;
  timing-function: ease-in-out;
}

.indigator-prx.on {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.btn {
  position: relative;
}

.btn:hover:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 100%;
  border: 2px solid #5b00c1;
  color: white;
  background-color: #5b00c1;
}

/********************************
main END
********************************/
/********************************
main banner START
********************************/
.top-banner {
  width: 1920px;
  min-width: 1200px;
  height: 100px;
  margin: 0 auto;
  padding: 30px 0;
  color: white;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/top-banner-bg.png");
}

.top-banner strong {
  display: inline-block;
  width: 100px;
  height: 35px;
  margin-right: 20px;
  font-size: 16px;
  line-height: 35px;
  text-align: center;
  border-radius: 30px;
  background-color: #2d2e3e;
}

.top-banner .text-msg {
  font-size: 24px;
}

.top-banner .text-notagain {
  margin-left: 190px;
  font-size: 14px;
}

.top-banner .text-notagain input {
  margin-right: 10px;
  border: 1px soli #73737f;
  background-color: transparent;
}

.top-banner .btn-close-banner {
  width: 16px;
  height: 16px;
  margin-left: 20px;
  font-size: 14px;
  width: 16px;
  height: 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/btn-close-banner.png");
}

/********************************
main banner END
********************************/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/********************************
slick START
********************************/
/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px;
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before, .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/********************************
slick END
********************************/
/********************************
slick: 회사개요 START
********************************/
.overview .slider-wrapper {
  position: relative;
}

.overview .slick-prev:before,
.overview .slick-next:before {
  color: transparent;
}

.overview .slick-prev,
.overview .slick-next {
  top: 714px;
  width: 10px;
  height: 16px;
}

.overview .slick-prev {
  left: 1138px;
}

.overview .slick-prev:before {
  width: 10px;
  height: 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/slick-prev.png");
}

.overview .slick-next {
  right: 358px;
}

.overview .slick-next:before {
  width: 10px;
  height: 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/slick-next.png");
}

.overview .slick-dots {
  left: 470px;
  bottom: -74px;
  width: 612px;
  height: 3px;
  background-color: #ddd;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: left;
  -moz-box-pack: left;
  -ms-flex-pack: left;
  -webkit-justify-content: left;
  justify-content: left;
}

.overview .slick-dots li {
  width: 204px;
  height: 3px;
  margin: 0;
  color: transparent;
}

.overview .slick-dots li.slick-active {
  background-color: #5b00c1;
}

.overview .slick-dots li button:before {
  color: transparent;
}

.overview .slick-paging {
  position: absolute;
  bottom: -80px;
  left: 375px;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 22px;
}

.overview .slick-paging strong {
  color: #333;
}

.overview .slick-paging span {
  color: #666;
}

.overview .slick-slider {
  margin-bottom: 0;
}

/********************************
slick: 회사개요 END
********************************/
/********************************
slick: 납품실적 START
********************************/
.delper .slider-wrapper {
  position: relative;
  height: 560px;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-image: url("../images/comp-delper-bg-1.png");
}

.delper .slick-prev:before,
.delper .slick-next:before {
  color: transparent;
}

.delper .slick-prev,
.delper .slick-next {
  top: 505px;
  width: 10px;
  height: 16px;
}

.delper .slick-prev {
  left: 1060px;
}

.delper .slick-prev:before {
  width: 10px;
  height: 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/slick-prev.png");
}

.delper .slick-next {
  right: 75px;
}

.delper .slick-next:before {
  width: 10px;
  height: 16px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/slick-next.png");
}

.delper .slick-dots {
  left: 335px;
  bottom: -55px;
  width: 660px;
  height: 3px;
  background-color: #ddd;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: left;
  -moz-box-pack: left;
  -ms-flex-pack: left;
  -webkit-justify-content: left;
  justify-content: left;
}

.delper .slick-dots li {
  width: 220px;
  height: 3px;
  margin: 0;
  color: transparent;
}

.delper .slick-dots li.slick-active {
  background-color: #5b00c1;
}

.delper .slick-dots li button {
  width: 100%;
  height: 100%;
}

.delper .slick-dots li button:before {
  color: transparent;
}

.delper .slick-paging {
  position: absolute;
  bottom: 45px;
  left: 235px;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 22px;
}

.delper .slick-paging strong {
  color: #333;
}

.delper .slick-paging span {
  color: #666;
}

.delper .slick-slider {
  margin-bottom: 0;
}

/********************************
slick: 납품실적 END
********************************/
/********************************
slick: 납품실적 상세 START
********************************/
.delperview .slider-wrapper {
  position: relative;
}

.delperview .slider {
  padding: 0 35px 0 55px;
}

.delperview .slider img {
  width: 250px;
  height: 180px;
  cursor: pointer;
}

.delperview .slick-prev:before,
.delperview .slick-next:before {
  color: transparent;
}

.delperview .slick-prev,
.delperview .slick-next {
  top: 80px;
  width: 18px;
  height: 30px;
}

.delperview .slick-prev {
  left: 0;
}

.delperview .slick-prev:before {
  width: 18px;
  height: 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/btn-prev-big.png");
}

.delperview .slick-next {
  right: 0;
}

.delperview .slick-next:before {
  width: 18px;
  height: 30px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/btn-next-big.png");
}

.delperview .slick-slider {
  margin-bottom: 0;
}

/********************************
slick: 납품실적 상세 END
********************************/
/********************************
slick: main 납품 및 시공사례 START
********************************/
.main-case .slider3-wrapper {
  position: relative;
  width: 1200px;
  height: 400px;
  margin-left: auto;
}

.main-case .slider3-wrapper .main-control-btn {
  position: absolute;
  top: 378px;
}

.main-case .slider3-wrapper .slick3-paging {
  position: absolute;
  top: 378px;
  left: -295px;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 22px;
}

.main-case .slider3-wrapper .slick3-paging strong {
  font-size: 30px;
  color: #5b00c1;
}

.main-case .slider3-wrapper .slick3-paging span {
  font-size: 24px;
  color: #666;
}

.main-case .slider3 {
  width: 1200px;
  height: 400px;
}

.main-case .slider3 .slick-dots {
  bottom: 0;
  width: 100%;
  height: 2px;
  text-align: left;
  background-color: #ddd;
}

.main-case .slider3 .slick-dots li {
  width: 150px;
  height: 2px;
  top: -4px;
  margin: 0;
}

.main-case .slider3 .slick-dots li button:before {
  color: transparent;
}

.main-case .slider3 .slick-dots .slick-active {
  background-color: #5b00c1;
}

.main-case .item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-direction: normal;
  -webkit-box-orient: vertical;
  -moz-box-direction: normal;
  -moz-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.main-case .case-pic {
  overflow: hidden;
  width: 282px;
  height: 240px;
  -webkit-transition-property: all;
  -moz-transition-property: all;
  -o-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  -o-transition-duration: 0.8s;
  transition-duration: 0.8s;
  -webkit-timing-function: ease-in-out;
  -moz-timing-function: ease-in-out;
  -o-timing-function: ease-in-out;
  timing-function: ease-in-out;
}

.main-case .case-pic-1 {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/main-case-pic-1.png");
}

.main-case .case-pic-2 {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/main-case-pic-2.png");
}

.main-case .case-pic-3 {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/main-case-pic-3.png");
}

.main-case .case-pic-4 {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/main-case-pic-4.png");
}

.main-case .case-pic-5 {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/main-case-pic-5.png");
}

.main-case .case-pic-6 {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/main-case-pic-6.png");
}

.main-case .case-pic-7 {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/main-case-pic-7.png");
}

.main-case .case-pic-8 {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url("../images/main-case-pic-8.png");
}

.main-case .case-caption h3 {
  margin-top: 35px;
  font-size: 24px;
  color: #333;
}

.main-case .case-caption p {
  margin-top: 20px;
  font-size: 16px;
  color: #999;
}

.main-case .slick-prev:before,
.main-case .slick-next:before {
  font-size: 40px;
  color: #333;
}

.main-case .slick-prev,
.main-case .slick-next {
  display: block !important;
  top: 380px;
}

.main-case .slick-prev {
  left: -365px;
}

.main-case .slick-next {
  right: 1375px;
}

/********************************
slick: main 납품 및 시공사례  END
********************************/
/********************************
slick: main 슬라이더 START
********************************/
.main-slider .slider2-wrapper {
  position: relative;
  height: 960px;
}

.main-slider .main-control-bg {
  z-index: 5;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 642px;
  height: 100px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/main-slider-bg.png");
}

.main-slider .pause {
  z-index: 5;
  position: absolute;
  top: 44px;
  left: 575px;
  width: 8px;
  height: 10px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/slick-btn-pause.png");
}

.main-slider .play {
  display: none;
  z-index: 5;
  position: absolute;
  top: 44px;
  left: 575px;
  width: 8px;
  height: 10px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/slick-btn-play.png");
}

.main-slider .paging {
  z-index: 5;
  position: absolute;
  top: 44px;
  color: white;
}

.main-slider .paging.current {
  left: 360px;
}

.main-slider .paging.total {
  left: 504px;
}

.main-slider .slick-prev:before,
.main-slider .slick-next:before {
  color: transparent;
}

.main-slider .slick-prev,
.main-slider .slick-next {
  z-index: 10;
  top: 909px;
  width: 7px;
  height: 10px;
}

.main-slider .slick-prev {
  left: 548px;
}

.main-slider .slick-prev:before {
  width: 7px;
  height: 10px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/main-slider-btn-prev.png");
}

.main-slider .slick-next {
  left: 592px;
}

.main-slider .slick-next:before {
  width: 7px;
  height: 10px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url("../images/main-slider-btn-next.png");
}

.main-slider .slick-dots {
  z-index: 10;
  left: 394px;
  bottom: 49px;
  width: 90px;
  height: 2px;
  background-color: #ad80e0;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: left;
  -moz-box-pack: left;
  -ms-flex-pack: left;
  -webkit-justify-content: left;
  justify-content: left;
}

.main-slider .slick-dots li {
  width: 30px;
  height: 2px;
  margin: 0;
  color: transparent;
}

.main-slider .slick-dots li.slick-active {
  background-color: white;
}

.main-slider .slick-dots li button:before {
  color: transparent;
}

.main-slider .slick-paging {
  position: absolute;
  top: 386px;
  left: -275px;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 22px;
}

.main-slider .slick-paging strong {
  font-size: 30px;
  color: #5b00c1;
}

.main-slider .slick-paging span {
  font-size: 24px;
  color: #666;
}

.main-slider .slick-slider {
  margin-bottom: 0;
}

/********************************
slick: main 슬라이더 END
********************************/
