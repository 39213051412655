/********************************
공지 상세 START
********************************/
main.noticeview {
    padding-top: 60px;
    padding-bottom: 150px;

    dl {
        margin-bottom: 40px;
        border-top: 2px solid #333;
        border-bottom: 1px solid #333;
    }

    dt {
        padding: 45px 30px;
        border-bottom: 1px solid #dddddd;
    }

    h2 {
        font-size: 24px;
        color: $text-dark;
    }

    .yymmdd {
        font-size: 16px;
    }

    .yymmdd-title {
        display: inline-block;
        margin-right: 20px; 
        color: $text-dark;
    }

    .yymmdd-text {
        color: $text-light;
    }
    
    dd {
        padding-left: 20px;
        padding-right: 20px;

        &.content {
            padding: 60px 30px;
            font-size: 16px;
            line-height: 32px;
        }

        &.attached {
            line-height: 50px;

            i {
                margin-right: 10px;
            }
            
            a {
                text-decoration: underline;
            }
        }
    }

    .nav-wrapper {
        border-top: 1px solid #ddd;
    }

    .link-prev,
    .link-next {
        height: 67px;
        line-height: 67px;

        strong {
            width: 130px; 
        }

        i {
            display: inline-block;
            margin-left: auto;
        }

        h3 {
            margin-left: 70px;
        }
    }

    .link-prev {
        i {
            @include bg(8, 4, icon-nav-arrow);
        }

        + .link-next {
            border-top: 1px solid #ddd;
        }
    }

    .link-next {
        i {
            transform: rotate(180deg);
            @include bg(8, 4, icon-nav-arrow);
        }
    }
}
/********************************
공지 상세 END
********************************/