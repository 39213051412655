/********************************
component: modal START
********************************/
.modal-backdrop {
    z-index: 10;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: 0.5;
}

.modal { 
    z-index: 15;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;  

    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
}

.modal-dialog {
    position: relative;
    max-width: 580px;

    @include flexbox();
    @include align-items(center);
}

.modal-content {
    width: 100%;
    background-color: white;

    @include flexbox();
    @include flex-direction(column);
}

.modal-header {
    @include flexbox();
    @include align-items(center); 

    .close {
        margin-top: 30px;
        margin-right: 30px;
        margin-left: auto;

        @include bg(25, 25, modal-btn-close);
    }
}

.modal-body {
    padding: 0 30px;
    text-align: center;

    i {
        display: inline-block;
        margin-bottom: 25px;
        
        @include bg(80, 80, modal-img-1);
    }
    
    span {
        display: block;
        font-size: 24px; 
        line-height: 30px;
        font-weight: 500; 
    }

    strong {
        color: $brand-color;
    }

    p {
        margin-top: 25px;
        font-size: 16px;
        line-height: 25px; 
        color: #666;
    }
}

.modal-footer {
    margin-top: 60px;

    .btn-primary {
        width: 100%;
        height: 60px;
        font-size: 16px;
        text-align: center;
        color: white;
        background-color: $brand-color;
    }    
}

/********************************
component: modal END
********************************/