/********************************
reset START
********************************/
*, :before, :after { 
    box-sizing: border-box; 
}  
html { 
    line-height: 1; 
    -ms-text-size-adjust: 100%; 
    -webkit-text-size-adjust: 100%; 
}
body,p,h1,h2,h3,h4,h5,h6,ul,ol,li,dl,dt,dd,table,th,td,form,fieldset,legend,input,textarea,button,select,div,figure { 
    margin:0; padding:0; 
}
body,input,textarea,select,button,table { 
    font-family:'Noto Sans','Roboto','맑은 고딕','Malgun Gothic','돋움',Dotum,Helvetica,AppleGothic,Sans-serif; 
    font-size:16px; 
    vertical-align:baseline; 
    color:#666; 
    word-break:keep-all; 
    word-wrap:break-word; 
}
img { max-width:100%; border:0; vertical-align:middle; }
ul, li{ list-style:none; }
dd{ word-break:keep-all; word-wrap:break-word; }
h1, h2, h3, h4, h5, h6 { font-weight:normal; font-size:inherit; }
em,address { font-style:normal; }
a { text-decoration:none; color:inherit; cursor: pointer;}
a:focus, a:active, a:hover { color:inherit; text-decoration:none; cursor:pointer; }
div:focus { outline:none; }
header, hgroup, footer, section, article, aside, nav, figure, figcaption, main, details, menu { 
    display:block; 
}
table{ border-collapse:collapse; table-layout:fixed; border-spacing:0; width:100%; empty-cells:show;  }
tbody, tfoot, thead, tr, th, td { word-wrap:break-word; }
caption,legend { display:block; position:absolute !important; left:-9999px !important; top:0 !important; text-indent:9999px !important; width:0 !important; height:0 !important; border:0 !important; overflow:hidden !important; font-size:1px; line-height:0 !important; white-space:nowrap !important; }
button { cursor:pointer; width:auto; overflow:visible; vertical-align:middle; background:none; margin:0; padding:0; border:0; background:transparent; border:none; outline: none; box-sizing:border-box; }
button::-moz-focus-inner, 
input[type="reset"]::-moz-focus-inner, 
input[type="button"]::-moz-focus-inner, 
input[type="submit"]::-moz-focus-inner, 
input[type="file"] > input[type="button"]::-moz-focus-inner { padding:0; border:0; }
input { background:none; overflow:visible; vertical-align:middle; box-sizing:border-box; }
input[type=text]::-ms-clear{display:none !important; }
[type="number"]::-webkit-inner-spin-button, 
[type="number"]::-webkit-outer-spin-button { height: auto; }
[type="search"] { -webkit-appearance: textfield; outline-offset: -2px; }
[type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration { -webkit-appearance: none; }
::-webkit-file-upload-button { -webkit-appearance: button; font: inherit; }
input:focus,
select:focus { border-color: none; outline: none; }
select::-ms-expand { display: none; }
fieldset { border:none; }
label { cursor:pointer; }
hr { overflow: visible; height: 0; margin:0; border: none; box-shadow: none;  }
i { font-style: normal;}
/* placeholder */
::-webkit-input-placeholder { color:inherit !important; }
::-moz-placeholder { color:inherit !important; }
:-ms-input-placeholder { color:inherit !important; }
:-moz-placeholder { color:inherit !important; }

/* accessibility */
.blind { display:block; position:absolute; left:-9999px; text-indent:9999px; width:0; height:0; border:0; overflow:hidden; font-size:1px; line-height:0; white-space:nowrap; }
/********************************
reset END
********************************/
