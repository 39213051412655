/********************************
회사 위치 START
********************************/
main.location {
    padding-top: 100px;
    padding-bottom: 140px;
    font-size: 18px;
    color: $text-dark;

    figure {
        width: 100%;
        height: 700px; 

        @include bg-pos(0,0,comp-location-img-1);
    }

    .list-info {
        margin-top: 80px;

        li {
            + li {
                margin-top: 20px;
            }
        }

        h2 {
            width: 255px;
            margin-right: 30px;
            border-right: 1px solid #dddddd;
            font-size: 38px;
            font-weight: 700;
        }

        strong { 
            display: inline-block;
            width: 115px;
            font-weight: 700; 
        }
    }

    hr {
        width: 100%;
        height: 5px;
        margin-top: 75px;
        background-color: #dddddd;
    }

    .list-trans {
        h2 {
            width: 230px;
            font-size: 24px;
            font-weight: 700;
        }

        >li {
            padding: 75px 0;
            border-top: 1px solid #e5e5e5;
        }
    }

    .list-trans-contents {
        li {
            + li {
                margin-top: 10px;
            }
        }
    }

    .icon-trans {
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-right: 7px;
        text-align: center;
        color: white;
        border-radius: 100%;

        &:before {
            display: inline-block;
            margin: 0 auto;
        }
    }

    .line-5 {
        background-color: #8445ce;

        &:before {
            content: '5';
            color: white;
        }    
    }

    .bus-blue {
        background-color: #8445ce;

        &:before {
            content: ' B';
            text-align: center;
            color: white;
        }
    }

    .bus-green {
        background-color: #5bb025;

        &:before {
            content: ' B';
            text-align: center;
            color: white;
        }
    }

    .bus-red {
        background-color: #f72f08;

        &:before {
            content: ' G';
            text-align: center;
            color: white;
        }
    }
}


/********************************
회사 위치 END
********************************/