/********************************
파트너 START
********************************/
main.partner {
    padding-top: 100px;
    padding-bottom: 150px;
    font-family: 'Roboto';
    font-weight: 500;

    .w-innder {
        background-color: salmon;
    }

    .tabs {
        margin-bottom: 80px;

        li { 
            border: 1px solid #dddddd;
            line-height:  60px;
            text-align: center;
            flex: 1 1 0;

            + li {
                margin-left: -1px;
            }

            a {
                display: block;
                width: 100%;
                height: 100%;
                color: #666;

                &.on,
                &:hover {
                    border: 1px solid #29244b;
                    color: white;
                    background-color: #29244b;
                }    
            }
        }

        a {
            width: 100%;
            height: 100%;
            font-size: 18px;
            font-weight: 300;
            color:#666;

            @include flexbox();
            @include align-items(center);            
            @include justify-content(center);
        }
    }

    header {
        margin-top: 70px;
        margin-bottom: 25px;
        font-size: 30px;
        font-family: Roboto;
        font-weight: 500;
        color: $text-dark;
    }

    .list {
        @include flex-wrap(wrap);

        li { 
            min-width: 185px;
            height: 100px;
            margin-right: 18px;
            margin-bottom: 18px;
            border: 1px solid #dddddd;

            &:nth-child(6n) {
                margin-right: 0;
            }
        }

        a {
            width: 100%;
            height: 100%;

            @include justify-content(center);
        }
    }
}
/********************************
파트너 END
********************************/
