/********************************
main START
********************************/
.main-wrapper {
    position: relative;
    left: 0;
    top: 0;
    max-width: 1920px;
    width: 1920px;
    min-width: 1200px;
    margin: 0 auto;

    .btn.type-more {
        &:hover {
            span, 
            i {
                position: relative;
                z-index: 5;
            }
         
            span {
                font-family: 'Roboto';
                font-weight: 500;
                color: white;
            }

            i {
                @include bg(24, 6, icon-arrow-more-white);
            }

            &:before {
                content:'';
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 57px;
                border-color: $brand-color;
                background-color: $brand-color;

                -webkit-animation: animate 400ms cubic-bezier(0.4, 0, 0.2, 1); 
                -moz-animation: animate 400ms cubic-bezier(0.4, 0, 0.2, 1); 
                -o-animation: animate 400ms cubic-bezier(0.4, 0, 0.2, 1); 
                animation: animate 400ms cubic-bezier(0.4, 0, 0.2, 1); 
            }
        }
    }

    @keyframes animate {
        from {
            width: 0;
        }
    }

    .btn.type-more.type-white {
        &:hover {    
            border-color: white;

            &:before {
                border-color: white;
                background-color: white;
            }

            span {
                color: $brand-color !important;
            }

            i {
                @include bg(24, 6, icon-arrow-more);
            }
        }
    }
}

.slider2 { 
    overflow: hidden;
    @include transition(all, 0.7s);
    // .slick-current.slick-active {
    //     -webkit-animation: animate2 8s ease-in-out forwards; 
    //     -moz-animation: animate2 8s ease-in-out forwards; 
    //     -o-animation: animate2 8s ease-in-out forwards; 
    //     animation: animate2 8s ease-in-out forwards; 
    // }
}

// @keyframes animate2 {
//     from {
//         -moz-transform: scale(1.2) perspective(0) rotateY(0deg);
//         -ms-transform: scale(1.2) perspective(0) rotateY(0deg);
//         transform: scale(1.2) perspective(0) rotateY(0deg);
//     }
//     to {
//         -moz-transform: scale(1.0) perspective(500px) rotateY(.001deg);
//         -ms-transform: scale(1.0) perspective(500px) rotateY(.001deg);
//         transform: scale(1.0) perspective(500px) rotateY(.001deg);
//     }
//   }

section.main-slider { 
    position: relative;

    .navbar { 
        z-index: 10;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        width: 100%;
        margin: 0 auto;
        border-bottom: none;

        a {
            color: white;
        }
    }

    .slider-caption {
        z-index: 5;
        position: absolute;
        top: 305px;
        left: 355px;
        color: white;
    }

    .en {
        font-size: 64px;
        line-height: 88px;
    }

    .en-prx {
        display: inline-block;
        position: relative;
        opacity: 0;

        @include translateX(-20px);  
        @include transition(all, 0.8s); 

        &.on {
            opacity: 1;

            @include translateX(0); 
        }
    }

    .ko {
        position: relative;
        margin-top: 40px;
        font-size: 18px;
        line-height: 32px;
    }  

    .btn.type-more.type-white {
        margin-top: 75px;
    }

    .ko-prx {
        opacity: 0;

        @include translateY(80px); 
        @include transition(all, 0.6s);

        &.on {
            opacity: 1;

            @include translateY(0); 
        }
    }

    .btn-prx {
        opacity: 0;

        @include translateY(80px); 
        @include transition(all, 0.8s);   
        
        &.on {
            opacity: 1;
            @include translateY(0); 
        }
    }

    /* 슬라이더 효과 START*/
    .slider2-wrapper {
        perspective: 500;

        .slider-track {
            -webkit-transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
            transition: all 1s cubic-bezier(0.7, 0, 0.3, 1);
        }
    }

    .main-pic {
        position: relative;
        z-index: 1;

        height: 100%;
        width: 1920px;
        background-size: cover;
        background-color: black;

        img {
            min-width: 100%;
            -webkit-transition: all 1s;
            transition: all 1s;
            -webkit-animation: cssAnimation 8s 1 ease-in-out;
            animation: cssAnimation 8s 1 ease-in-out;
        }
    }

    .slick-current,
    .slick-active {
        transform:perspective(500px) rotate(.001deg); //explore

        img {
            -webkit-animation: cssAnimation 8s 1 ease-in-out;
            animation: cssAnimation 8s 1 ease-in-out;
        }
    } 

    .cssAnimationEffect {
        -webkit-animation: cssAnimation 8s 1 ease-in-out;
        animation: cssAnimation 8s 1 ease-in-out;
    }

    @keyframes cssAnimation {
        0% {
            -webkit-transform: scale(1.2) translate(0px);
            transform: scale(1.2) translate(0px);
        }
        100% {
            -webkit-transform: scale(1.0) translate(0px);
            transform: scale(1.0) translate(0px);

        }
    }

    @-webkit-keyframes cssAnimation {
        0% {
            -webkit-transform: scale(1.2) translate(0px);
            transform: scale(1.2) translate(0px);
        }
        100% {
            -webkit-transform: scale(1.0) translate(0px);
            transform: scale(1.0) translate(0px);
        }
    }
    /* 슬라이더 효과 END*/
    
    .main-pic-1 {
        @include bg-pos(0, 0, main-slider-1);
    }

    .main-pic-2 {
        @include bg-pos(0, 0, main-slider-2);
    }

    .main-pic-3 {
        @include bg-pos(0, 0, main-slider-3);
    }
} 

section.main-biz { 
    position: relative;
    height: 1800px;
    border: 1px solid transparent;

    .en {
        text-transform: uppercase;
    }

    .pic {
        width: 540px;
        height: 680px;

        @include transition(all, 0.8s);

        &.pic-1 {
            margin-right: 100px;

            @include bg-pos(0, 0, main-biz-pic-1);
        }

        &.pic-2 {
            position: relative;
            top: -40px;
            margin-left: auto;

            @include bg-pos(0, 0, main-biz-pic-2);
        }
    }

    .txt {
        position: absolute;

        &.text-1 {
            top: 130px;
            left: 0;
        }

        &.text-2 {
            bottom: 129px;
            right: 0;
        }
    }

    ul {
        margin-top: 240px;
    }

    > li {
        position: relative;
        height: 680px;

        &:first-child {
            .content {
                margin-left: 100px;
            }
        }

        &:last-child {
            margin-right: auto;

            .content {
                margin-left: 102px;
            }
        }
    }

    strong {
        display: block;
        margin-top: 113px;
        color: #333;
    }

    h2 {
        margin-top: 30px;
        font-size: 54px;
        line-height: 74px;
        color: #333; 
    }

    p {
        margin-top: 40px;
        font-size: 18px;
        line-height: 30px;
    }

    button {
        margin-top: 54px;
    }

    .en-prx-2,
    .en-prx {
        opacity: 0;

        @include transition(all, 0.8s);   
        
        &.on {
            opacity: 1;
        }
    }

    .ko-prx {
        opacity: 0;

        @include translateX(100px); 
        @include transition(all, 0.8s);   
        
        &.on {
            opacity: 1;
            @include translateX(0); 
        }
    }

    .btn-prx {
        opacity: 0;

        @include translateX(100px); 
        @include transition(all, 0.8s);   
        
        &.on {
            opacity: 1;
            @include translateX(0); 
        }
    }

    .ko-prx-2 {
        opacity: 0;

        @include translateX(-100px); 
        @include transition(all, 0.8s);   
        
        &.on {
            opacity: 1;

            @include translateX(0); 
        }
    }
    
    .btn-prx-2 {
        opacity: 0;

        @include translateX(-100px); 
        @include transition(all, 0.8s);   
        
        &.on {
            opacity: 1;
            @include translateX(0); 
        }
    }
}

section.main-client {
    position: relative;
    height: 840px;
    background-color: $brand-color;

    .en {
        text-transform: uppercase;
    }

    .text-1 {
        position: absolute;
        left: 0;
        top: 0;
    }

    .cli-inner {
        @include flex-direction(column);
        @include justify-content(center);
    }

    header {
        margin-top: 120px;
        text-align: center;
        color: white;
    }

    .en {
        font-size: 16px;
    }

    .ko {
        margin-top: 20px;
        font-size: 54px;
    }

    ul {
        margin-top: 50px;

        @include flex-wrap(wrap);
        @include justify-content(center);
    }

    li {
        width: 283px;
        height: 200px; 
        margin-right: 22px;
        margin-bottom: 22px;
        background-color: white;

        &:nth-child(4n) {
            margin-right: 0;
        }
    }

    a {
        width: 100%;
        height: 100%;

        @include justify-content(center);
    }

    .cli-item-prx {
        opacity: 0;

        @include translateX(-20px); 
        @include transition(all, 0.8s); 
        
        &.on {
            opacity: 1;

            @include translateX(0); 
        }
    }
}

section.main-case {
    position: relative;
    height: 890px;
    padding-top: 240px;
    
    .en {
        font-family: 'Roboto';
        font-weight: 500;
        text-transform: uppercase;
    }

    .ko {
        font-weight: 500;
    }

    .text-1 {
        position: absolute;
        right: 0;
        top: 130px;
    }

    header {
        margin-left: 360px;
        margin-right: 100px;
    }

    .en {
        font-size: 16px;
        color: $text-dark;
    }

    .ko {
        margin-top: 20px;
        font-size: 54px;
        line-height: 74px;
        color: $text-dark;
    }
}  

section.main-inquiry {
    height: 320px;
    color: white;

    @include bg-pos(0, 0, main-inquiry-bg);

    .en {
        font-size: 16px;
        text-transform: uppercase;
    }

    .ko {
        margin-top: 20px;
        font-size: 54px;
    }
}

.indigator {
    z-index: 10;
    position: fixed;
    top: 50%;
    width: 170px;
    height: 100vh;

    li {
        position: relative;
        padding-left: 80px;
        text-transform: uppercase;
        color: $text-dark;
        cursor: pointer;

        + li {
            margin-top: 19px;
        }

        &.on,
        &:hover {
            color: $brand-color;

            &:before {
                content:'';
                display: block;
                position: absolute;
                left:0;
                top: 8px;
                width: 60px;
                max-width: 60px;
                height: 2px;
                background-color: $brand-color;

                -webkit-animation: animate 400ms ease-in-out forwards; 
                -moz-animation: animate 400ms ease-in-out forwards; 
                -o-animation: animate 400ms ease-in-out forwards; 
                animation: animate 400ms ease-in-out forwards; 
            }
        }

        &.on2 {
            color: #b783f1;
        }

        &.cli-prx {
            &.on2 {
                color: white;  

                &:before {
                    color: white; 
                    background-color: white;
                }
            } 
        }
    }
}

.indigator-prx {
    opacity: 0;

    @include translateX(-200px); 
    @include transition(all, 0.8s); 
    
    &.on {
        opacity: 1;
        @include translateX(0); 
    }
}

.btn {
    position: relative;

    &:hover {
        &:before {
            content:'';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            max-width: 100%;
            border: 2px solid $brand-color;
            color: white;
            background-color: $brand-color;
        }
    }
}
/********************************
main END
********************************/

/********************************
main banner START
********************************/
.top-banner {
    width: 1920px;
    min-width: 1200px;
    height: 100px;
    margin: 0 auto;
    padding: 30px 0;
    color: white;
    
    @include justify-content(center);
    @include bg-pos(0, 0, top-banner-bg);

    strong {
        display: inline-block;
        width: 100px;
        height: 35px;
        margin-right: 20px;
        font-size: 16px;
        line-height: 35px;
        text-align: center;
        border-radius: 30px;
        background-color: #2d2e3e;
    }

    .text-msg {
        font-size: 24px;
    }

    .text-notagain {
        margin-left: 190px;
        font-size: 14px;

        input {
            margin-right: 10px;
            border: 1px soli #73737f;
            background-color: transparent;
        }
    }

    .btn-close-banner {
        width: 16px;
        height: 16px;
        margin-left: 20px;
        font-size: 14px;
        
        @include bg(16, 16, btn-close-banner);
    }
}
/********************************
main banner END
********************************/