/********************************
제품소개 START
********************************/
main.product {
    padding-top: 80px;
    padding-bottom: 120px;

    .prd-pic {
        height: 600px;
        margin-bottom: 40px;
        background-color: #f3f2f8;
    }

    .prd-inner {
        position: relative;
        height: 600px;
    }

    .prd-img {
        width: 404px;
        height: 437px;
        margin-left: auto;
    }

    .prd-title {
        margin-top: 55px;
        margin-bottom: auto;

        span {
            display: inline-block;
            margin-bottom: 20px;
            border-bottom: 1px solid $brand-color;
            font-size: 18px;
            font-family: 'Roboto';
            font-weight: 500; 
        }

        h2 {
            font-size: 54px;
            line-height: 72px;
        }
    }

    .sumnail-list {
        position: absolute;
        left: 0;
        bottom: 60px;
    }

    .sumnail-item {
        position: relative;
        width: 120px;
        height: 79px;
        border:1px solid #ddd;

        + .sumnail-item {
            margin-left: 10px;
        }
        
        &.on,
        &:hover {
            &:before {
                content:'';
                position: absolute;
                left: -1px;
                top: -1px;
                width: 120px;
                height: 79px;
                border:2px solid $brand-color;
                opacity: 0.7;
                background-color: white;
            }
        }
    }   

    .sumnail-link {
        width: 100%;
        height: 100%;

        @include justify-content(center);
    }

    .sumnail-img {
        width: 55px;
        height: 60px;
    }

    dl {
        + dl {
            border-top: 1px solid #ddd;
        }
    }

    .title, 
    .content {
        padding: 40px 0;
    }

    .title {
        min-width: 300px;
        font-size: 24px;
        font-family: 'Roboto';
        font-weight: 500;
        color: $text-dark; 
    }

    .content.designation {
        dt {
            margin-right: 20px;
        }

        p {
           font-size: 18px;
           color: $text-dark; 
        }

        dd {
            + dt {
                margin-left: 90px;
            }
        }
    }

    h3 {
        margin-top: 20px;
        font-size: 30px;
        font-family: 'Roboto';
        font-weight: 500;
        color: $text-dark;
    }

    .overview-item {
        padding-left: 18px; 
        font-size: 18px;
        line-height: 36px;

        @include flexbox();
        @include align-items(center);

        &:before {
            content:'';
            display: inline-block;
            margin-right: 12px;

            @include bg(7, 8, icon-dot);
        }
    }

    .btn-download {
        + .btn-download {
            margin-left: 10px;
        }
    }

    table {
        text-align: left;
    }
    
    th {
        font-weight: 500;
        color: $text-dark;
    }

    th, td {
        padding: 20px 0;
        border-bottom: 1px solid #ddd;
    }

    col:nth-child(1) {
        width: 150px;
    }

    tr {
        &:last-child {
            th, td {            
                border-bottom: none;
            }
        }
    }
}
/********************************
제품소개 END
********************************/