/********************************
고객센터 질문하기 START
********************************/
main.inquiry {
    padding-top: 60px;
    padding-bottom: 150px;

    .table-wrapper {
        border-top: 2px solid #333;
        border-bottom: 1px solid #333;
    }
    table {
        table-layout: auto;
    }
    
    th {
        font-weight: 500;
        color: $text-dark;
    }

    th,
    td { 
        padding: 10px 30px; 
        border-bottom: 1px solid #ddd;
        text-align: left;
    }

    input,
    textarea {
        border: none;
        background-color: #f4f4f4;
    }

    textarea {
        height: 250px;
    }

    .attention {
        margin-top: 20px;
        font-size: 14px;
        line-height: 24px;
    }

    .form-btn-group {
        margin-top: 40px;
        
        .btn + .btn {
            margin-left: 12px;
        }
    }
}

/********************************
고객센터 질문하기 END
********************************/