@charset "UTF-8";
/********************************
slick START
********************************/
// Default Variables

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "←" !default;
$slick-next-character: "→" !default;
$slick-dot-character: "•" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

.slick-list {
    .slick-loading & {
        background: #fff slick-image-url("ajax-loader.gif") center center no-repeat;
    }
}

/* Icons */
@if $slick-font-family == "slick" {
    @font-face {
        font-family: "slick";
        src: slick-font-url("slick.eot");
        src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
        font-weight: normal;
        font-style: normal;
    }
}

/* Arrows */

.slick-prev,
.slick-next {
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    background: transparent;
    color: transparent;
    top: 50%;
    margin-top: -10px;
    padding: 0;
    border: none;
    outline: none;
    &:hover, &:focus {
        outline: none;
        background: transparent;
        color: transparent;
        &:before {
            opacity: $slick-opacity-on-hover;
        }
    }
    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
}

.slick-prev:before, .slick-next:before {
    font-family: $slick-font-family;
    font-size: 20px;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
    left: -25px;
    [dir="rtl"] & {
        left: auto;
        right: -25px;
    }
    &:before {
        content: $slick-prev-character;
        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}

.slick-next {
    right: -25px;
    [dir="rtl"] & {
        left: -25px;
        right: auto;
    }
    &:before {
        content: $slick-next-character;
        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}

/* Dots */

.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -45px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    width: 100%;
    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: $slick-dot-character;
                width: 20px;
                height: 20px;
                font-family: $slick-font-family;
                font-size: $slick-dot-size;
                line-height: 20px;
                text-align: center;
                color: $slick-dot-color;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
        &.slick-active button:before {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}
/********************************
slick END
********************************/

/********************************
slick: 회사개요 START
********************************/
.overview {
    .slider-wrapper {
        position: relative;
    }

    .slick-prev:before,
    .slick-next:before {
        color: transparent;
    } 

    .slick-prev,
    .slick-next {
        top: 714px;
        width: 10px;
        height: 16px;
    }

    .slick-prev {
        left: 1138px;

        &:before {
            @include bg(10, 16, slick-prev);  
        }
    } 

    .slick-next {
        right: 358px;

        &:before {
            @include bg(10, 16, slick-next);
        }
    }

    .slick-dots {
        left: 470px;
        bottom: -74px;
        width: 612px;
        height: 3px;
        background-color: #ddd;

        @include flexbox();
        @include justify-content(left);

        li {
            width: 204px; 
            height: 3px;
            margin: 0;
            color: transparent;

            &.slick-active {
                background-color: $brand-color;
            }

            button { 
                &:before {
                    color: transparent;
                }
            }
        }
    }

    .slick-paging {
        position: absolute;
        bottom: -80px;
        left: 375px;
        font-family: 'Roboto';
        font-weight: 300;
        font-size: 22px;
    
        strong {
            color: $text-dark;
        }
    
        span {
            color: $text-base;
        }
    }

    .slick-slider {
        margin-bottom: 0;
    }
}
/********************************
slick: 회사개요 END
********************************/

/********************************
slick: 납품실적 START
********************************/
.delper {
    .slider-wrapper {
        position: relative;
        height: 560px;
        
        @include bg-pos(100%, 100%, comp-delper-bg-1);
    }
        
    .slick-prev:before,
    .slick-next:before {
        color: transparent;
    } 

    .slick-prev,
    .slick-next {
        top: 505px;
        width: 10px;
        height: 16px;
    }

    .slick-prev {
        left: 1060px;

        &:before {
            @include bg(10, 16, slick-prev);
        }
    } 

    .slick-next {
        right: 75px;

        &:before {
            @include bg(10, 16, slick-next);
        }
    }

    .slick-dots {
        left: 335px;
        bottom: -55px;
        width: 660px;
        height: 3px;
        background-color: #ddd;

        @include flexbox();
        @include justify-content(left);

        li {
            width: 220px; 
            height: 3px;
            margin: 0;
            color: transparent;

            &.slick-active {
                background-color: $brand-color;
            }

            button { 
                width: 100%;
                height: 100%;

                &:before {
                    color: transparent;
                }
            }
        }
    }

    .slick-paging {
        position: absolute;
        bottom: 45px;
        left: 235px;
        font-family: 'Roboto';
        font-weight: 300;
        font-size: 22px;
    
        strong {
            color: $text-dark;
        }
    
        span {
            color: $text-base;
        }
    }

    .slick-slider {
        margin-bottom: 0;
    }
}
/********************************
slick: 납품실적 END
********************************/

/********************************
slick: 납품실적 상세 START
********************************/
.delperview {
    .slider-wrapper {
        position: relative;
    }

    .slider {
        padding: 0 35px 0 55px;

        img {
            width: 250px;
            height: 180px;

            cursor: pointer;
        }
    }    

    .slick-prev:before,
    .slick-next:before {
        color: transparent;
    } 

    .slick-prev,
    .slick-next {
        top: 80px;
        width: 18px;
        height: 30px;
    }

    .slick-prev {
        left: 0;

        &:before {
            @include bg(18, 30, btn-prev-big);
        }
    } 

    .slick-next {
        right: 0;

        &:before {
            @include bg(18, 30, btn-next-big);
        }
    }

    .slick-slider {
        margin-bottom: 0;
    }
}
/********************************
slick: 납품실적 상세 END
********************************/

/********************************
slick: main 납품 및 시공사례 START
********************************/
.main-case {
    .slider3-wrapper { 
        position: relative;
        width: 1200px;
        height: 400px;
        margin-left: auto;

        .main-control-btn {
            position: absolute;
            top: 378px;
        }
        .slick3-paging {
            position: absolute;
            top: 378px;
            left: -295px;
            font-family: 'Roboto';
            font-weight: 300;
            font-size: 22px;
        
            strong {
                font-size: 30px;
                color: $brand-color;
            }
        
            span {
                font-size: 24px;
                color: $text-base;
            }
        }
    }
    
    .slider3 {
        width: 1200px;
        height: 400px;

        .slick-dots {
            bottom: 0;
            width: 100%;
            height: 2px;
            text-align: left;
            background-color: #ddd;
           
            li {
                width: 150px;
                height: 2px;
                top: -4px;
                margin: 0;

                button {
                    &:before {
                        color: transparent;
                    }
                }
            }

            .slick-active {
                background-color: $brand-color; 
            }
        }
    }   

    .item {
        @include flexbox();
        @include justify-content(space-between);
        @include flex-direction(column);
    }
    
    .case-pic {
        overflow: hidden;
        width: 282px;
        height: 240px;

        @include transition(all, 0.8s); 
    }

    .case-pic-1 {
        @include bg-pos(0, 0, main-case-pic-1);
    }

    .case-pic-2 {
        @include bg-pos(0, 0, main-case-pic-2);
    }

    .case-pic-3 {
        @include bg-pos(0, 0, main-case-pic-3);
    }

    .case-pic-4 {
        @include bg-pos(0, 0, main-case-pic-4);
    }

    .case-pic-5 {
        @include bg-pos(0, 0, main-case-pic-5);
    }
    
    .case-pic-6 {
        @include bg-pos(0, 0, main-case-pic-6);
    }

    .case-pic-7 {
        @include bg-pos(0, 0, main-case-pic-7);
    }

    .case-pic-8 {
        @include bg-pos(0, 0, main-case-pic-8);
    }

    .case-caption {
        h3 {
            margin-top: 35px;
            font-size: 24px;
            color: $text-dark;
        }

        p {
            margin-top: 20px;
            font-size: 16px;
            color: $text-light;
        }
    } 

    .slick-prev:before,
    .slick-next:before {
        font-size: 40px;
        color: #333;
    } 

    .slick-prev,
    .slick-next {
        display: block !important;
        top: 380px;
    }

    .slick-prev {
        left: -365px;
    } 

    .slick-next {
        right: 1375px;
    }
}
/********************************
slick: main 납품 및 시공사례  END
********************************/

/********************************
slick: main 슬라이더 START
********************************/
.main-slider {
    .slider2-wrapper {
        position: relative;
        height: 960px;
    }

    .main-control-bg {
        z-index: 5;
        position: absolute;
        left: 0;
        bottom: 0;

        @include bg(642, 100, main-slider-bg);
    }

    .pause {
        z-index: 5;
        position: absolute;
        top: 44px;
        left: 575px;
        
        @include bg(8, 10, slick-btn-pause);
    }

    .play {
        display: none;
        z-index: 5;
        position: absolute;
        top: 44px;
        left: 575px;
        
        @include bg(8, 10, slick-btn-play);
    }
    
    .paging {
        z-index: 5;
        position: absolute;
        top: 44px;
        color: white;
        
        &.current {
            left: 360px;
        }

        &.total {
            left: 504px;
        }
    }
    
    .slick-prev:before,
    .slick-next:before {
        color: transparent;
    } 

    .slick-prev,
    .slick-next {
        z-index: 10;
        top: 909px;
        width: 7px;
        height: 10px;
    }

    .slick-prev {
        left: 548px;

        &:before {
            @include bg(7, 10, main-slider-btn-prev);
        }
    } 

    .slick-next {
        left: 592px;

        &:before {
            @include bg(7, 10, main-slider-btn-next);
        }
    }

    .slick-dots {
        z-index: 10;
        left: 394px;
        bottom: 49px;
        width: 90px;
        height: 2px;
        background-color: #ad80e0;

        @include flexbox();
        @include justify-content(left);

        li {
            width: 30px; 
            height: 2px;
            margin: 0;
            color: transparent;

            &.slick-active {
                background-color: white;
            }

            button { 
                //width: 100%;
                //height: 100%;

                &:before {
                    color: transparent;
                }
            }
        }
    }

    .slick-paging {
        position: absolute;
        top: 386px;
        left: -275px;
        font-family: 'Roboto';
        font-weight: 300;
        font-size: 22px;
    
        strong {
            font-size: 30px;
            color: $brand-color;
        }
    
        span {
            font-size: 24px;
            color: $text-base;
        }
    }

    .slick-slider {
        margin-bottom: 0;
    }
}
/********************************
slick: main 슬라이더 END
********************************/