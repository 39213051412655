/********************************
font START
********************************/
@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 100;
	src: local('Noto Sans Light'), local('NotoSans-Light'), 
	url(../fonts/NotoSansCJKkr-DemiLight.eot),
	url(../fonts/NotoSansCJKkr-DemiLight.eot?#iefix) format('embedded-opentype'),
	url(../fonts/NotoSansCJKkr-DemiLight.woff) format('woff');
}

@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 300;
	src: local('Noto Sans Regular'), local('NotoSans-Regular'), 
	url(../fonts/NotoSansCJKkr-Regular.eot),
	url(../fonts/NotoSansCJKkr-Regular.eot?#iefix) format('embedded-opentype'),
	url(../fonts/NotoSansCJKkr-Regular.woff) format('woff');
}

@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 500;
	src: local('Noto Sans Medium'), local('NotoSans-Medium'), 
	url(../fonts/NotoSansCJKkr-Medium.eot),
	url(../fonts/NotoSansCJKkr-Medium.eot?#iefix) format('embedded-opentype'),
	url(../fonts/NotoSansCJKkr-Medium.woff) format('woff');
}

@font-face {
	font-family: 'Noto Sans';
	font-style: normal;
	font-weight: 700;
	src: local('Noto Sans Bold'), local('NotoSans-Bold'), 
	url(../fonts/NotoSansCJKkr-Bold.eot),
	url(../fonts/NotoSansCJKkr-Bold.eot?#iefix) format('embedded-opentype'),
	url(../fonts/NotoSansCJKkr-Bold.woff) format('woff');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 100;
	src: local('Roboto Light'), local('Roboto-Light'), 
	url(../fonts/Roboto-Light.eot),
	url(../fonts/Roboto-Light.eot?#iefix) format('embedded-opentype'),
	url(../fonts/Roboto-Light.woff) format('woff');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 300;
	src: local('Roboto Regular'), local('Roboto-Regular'), 
	url(../fonts/Roboto-Regular.eot),
	url(../fonts/Roboto-Regular.eot?#iefix) format('embedded-opentype'),
	url(../fonts/Roboto-Regular.woff) format('woff');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: local('Roboto Medium'), local('Roboto-Medium'), 
	url(../fonts/Roboto-Medium.eot),
	url(../fonts/Roboto-Medium.eot?#iefix) format('embedded-opentype'),
	url(../fonts/Roboto-Medium.woff) format('woff');
}

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: local('Roboto Bold'), local('Roboto-Bold'), 
	url(../fonts/Roboto-Bold.eot),
	url(../fonts/Roboto-Bold.eot?#iefix) format('embedded-opentype'),
	url(../fonts/Roboto-Bold.woff) format('woff');
}
/********************************
font END
********************************/