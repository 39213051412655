/********************************
component: dropdown START
********************************/
.dropdown {
    position: relative;

    .dropdown-toggle {
        padding-right: 10px;
        font-size: 16px;
        line-height: 48px;
        text-align: left;

        @include bg-pos(95%, 50%, icon-arrow-south);

        &.on {
            @include bg-pos(95%, 50%, icon-arrow-north);    
        }
    }

    .dropdown-menu {
        display: none;
        z-index: 10;
        position: absolute;
        top: 100%;
        left: 0;
        float: left;
        min-width: 100%;
        margin-top: -1px;
        border: 1px solid #dddddd;
        text-align: left;
        background-color: #fff;
    }

    .dropdown-item {
        display: block;
        width: 100%;
        height: 48px;
        padding: 0 1.5rem;
        clear: both;
        font-size: 16px;
        line-height: 48px;
        text-align: inherit;
        white-space: nowrap;
        color: $text-dark;
        background-color: transparent;
    }
}
/********************************
component: dropdown END
********************************/