/********************************
layout START
********************************/
.wrapper {
    overflow-x: hidden;
    max-width: 1920px;
    min-width: 1200px;
    margin: 0 auto;
}

.submain {
    height: 400px;

    .submain-bar {
        height: 100%;
        color: white;
        background: url('../images/submain-aside-bg.png') 0 100% no-repeat; 
    }
    
    .submain-inner {  
        height: 100%;      
        
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(center);
    }

    header {
        margin-top: 175px;
    }

    .en {
        font-size: 18px;
        line-height: 36px;
    }
    
    .ko {
        font-size: 54px;
    }

    &.type-comp {
        @include bg-pos(0, 0, comp-submain);
    }

    &.type-product {
        @include bg-pos(0, 0, product-submain);
    }

    &.type-arremote {
        @include bg-pos(0, 0, arremote-submain);
    }

    &.type-refs {
        @include bg-pos(0, 0, refs-submain);
    }

    &.type-support {
        @include bg-pos(0, 0, support-submain);
    }
}
/********************************
layout END
********************************/