/********************************
회사 개요 START
********************************/
main.overview {
    padding-top: 100px;
    padding-bottom: 150px;
    background-color: #f8f8f8;

    .content {
        height: 640px;

        @include bg-pos(0, 0, comp_overview_img_1);        
    }
    
    .w-inner {
        height: 640px;
    }

    .paragraph { 
        height: 427px;
        margin-top: auto;
        padding-top: 70px;
        padding-left: 70px;
        padding-right: 65px;
        color: #333;
        background-color: white;
    }

    .title {
        font-size: 28px;
        line-height: 46px;
        font-weight: 500;
    }

    .text {
        padding-top: 30px;
        font-size: 18px;
        line-height: 2;
        font-weight: 100;
    }

    strong {
        color: #5b00c1;
    }

    .slider-wrapper { 
        position: relative;
        margin-top: 145px;
        width: 1560px;
        margin-left: 360px;

        .paragraph {
            z-index: 10;
            position: absolute;
            left: 0;
            bottom: 0;
        }

        img {
            margin-left: 360px;
        }
    }
}
/********************************
회사 개요 END
********************************/