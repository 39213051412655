/********************************
navbar START
********************************/
.navbar {
    z-index: 15;
    position: absolute;
    left: 0;
    right: 0;
    max-width: 1920px;
    min-width: 1200px;
    min-height: 100px;
    border-bottom: 1px solid rgba(255, 255, 255, .1);
    margin: 0 auto;
    background-color: transparent;
    
    @include transition(all, 0.4s); 

    .logo {
        position: absolute;
        top: 30px;
        left: 40px;
        margin-left: 40px;

        @include bg(232, 38, logo);
    }

    nav {
        padding: 0 204px;
    }

    .nav-item {
        position: relative;
        height: 100%;
        padding: 0 50px;
        align-self: center;
    }

    .nav-link {
        display: block;
        width: 100%;
        height: 100px;
        line-height: 99px;
        font-size: 18px;

        + .nav-link {
            margin-left: 95px;
        }
    }

    .btn-hamburger {
        position: absolute;
        top: 38px;
        right: 30px;
    
        @include bg(32, 22, btn-hamburger);
    }

    .sub {
        display: none;
    }
}

.navbar {
    &.on {
        max-width: 1920px;
        min-width: 1200px; 
        height: 170px !important;
        margin: 0 auto;
        background-color: white;
    
        // &:before {
        //     content: '';
        //     position: absolute;
        //     top: 100px;
        //     width: 100%;
        //     height: 1px;
        //     opacity: 0.9;
        //     background-color: #dddddd;
        // }
    
        .logo {
            @include bg(232, 38, logo-blue);
        }
    
        .nav-link {
            color: $text-dark;
    
            &.on,
            &:hover {
                border-bottom: 2px solid $brand-color;
    
                + .sub {
                    display: block 
                }
            }
        }
    
        .sub {
            position: absolute;
            left: 50px;
            top: 100%;
        }
    
        .sub-item {
            height: 70px;
            line-height: 70px;
    
            + .sub-item {
                margin-left: 40px;
            }
        }
    
        .sub-link {
            display: block;
            white-space: nowrap;
            color: #666;
    
            &:hover {
                font-weight: 500;
                color: $brand-color;
            }
        }
    
        .btn-hamburger {
            @include bg(32, 22, btn-hamburger-black);
        }
    }
} 

.navbar {
    &.is-fixed {
        position: fixed !important;
        left: 0;
        right: 0;
        max-width: 1920px;
        min-width: 1200px; 
        min-height: 100px;
        margin: 0 auto;
        background-color: white;
       
        .logo {
            @include bg(232, 38, logo-blue);
        }
    
        .nav-link {
            color: $text-dark;
        }    
        
        .sub {
            display: none;
        }
    
        .btn-hamburger {
            @include bg(24, 24, btn-hamburger-black);
        }
    }    
}

/********************************
navbar END
********************************/

/********************************
navbar modal START
********************************/
.modal-backdrop {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: 0.5;
}

.navbar-modal {
    z-index: 15;
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;  
}    

.navbar-modal-dialog {
    position: relative;
    max-width: 1920px;
    min-width: 1200px; 
    //width: 1920px;
    height: 400px;
    margin: 0 auto;
    background-color: white;

    .header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100px;
        border-bottom: 1px solid #ddd;
    }

    .logo-blue {
        display: inline-block;
        position: absolute;
        top: 30px;
        left: 40px; 

        @include bg(232, 38, logo-blue);
    }

    nav {
        padding: 0 180px;

        ul {
            @include justify-content(Space-between);
        }
    }

    .nav-item {
        position: relative;
        font-size: 18px;
    }

    .nav-link {
        display: block;
        height: 99px;
        font-size: 18px;
        line-height: 100px;
        text-align: center;
        color: $text-dark;

        &:hover {
            border-bottom: 2px solid $brand-color;
        }
    }

    .sub {
        margin-top: 40px;
    }
    
    .sub-item {
        + .sub-item {
            margin-top:20px;
        }

        flex: 1 1 0;
    }

    .sub-link {
        display: block;
        width:100%;
        height: 100%;
        font-weight: 500;
        text-align: center;
        color: #666;

        &.on,
        &:hover {
            color: $brand-color;
        }
    }
}

.btn-hamburger-modal {
    position: absolute;
    top: 40px;
    right: 40px;

    @include bg(24, 24, modal-btn-close);
}
/********************************
navbar modal END
********************************/