/********************************
component: input file type START
********************************/
.input-file { 
    display: inline-block;
    position: relative;

    input[type="file"] { 
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 0;
        margin: 0;
        cursor: pointer;
        filter: alpha(opacity=0);
        opacity: 0; 
    }
    
    label {
        display: inline-block; 
        min-width: 120px; 
        height: 48px; 
        border: 2px solid  #29244b; 
        font-size: 16px; 
        font-weight: 500;;
        line-height: 48px; 
        text-align: center; 
        vertical-align: middle;
        color: #29244b;    
    }

    input[type="text"] { 
        min-width: 290px; 
        height: 48px; 
        border: 1px solid #f5f5f5;
        line-height: 48px;
        text-indent: 20px; 
        background: #f5f5f5; 

        + label {
            margin-left: 6px;
        }
    }

    .file-focus { 
        outline: 1px dotted #d2310e; 
    }
}
/********************************
component: input file type END
********************************/